import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { DataContext } from "../../contexts/DataContext";
import { SocketContext } from "../../contexts/SocketContext";
import { UIContext } from "../../contexts/UIContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import moment from "moment";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Profile = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #de00ff;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: white;
`;

const Connected = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  background-color: ${(props) => (props.connected ? "#0adb93" : "red")};
  border-radius: 50%;
  margin: 0.5em;
`;

const ServerConnected = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  background-color: ${(props) => (props.connected ? "#0adb93" : "red")};
  color: #fff;
  margin: 0.5em;
  padding: 0.2em;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 21%);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
export default function Monitoring() {
  const {
    monitorGames,
    server,
    monitorMachines,
    subscribeToMonitor,
  } = useContext(SocketContext);

  useEffect(() => {
    subscribeToMonitor();
  }, []);

  return (
    <Main>
      <Grid style={{ width: "100%" }}>
        <Grid.Unit size={1 / 5} style={{ marginTop: ".5em" }}>
          <ServerConnected connected={server?.connected}>
            {server?.name}
          </ServerConnected>
        </Grid.Unit>
      </Grid>
      {/* <Grid style={{ width: "100%" }}>
        {monitorGames.map((g, index) => {
          return (
            <Grid.Unit size={1 / 5} style={{ marginTop: ".5em" }}>
              <Panel
                style={{
                  backgroundColor: g.connected ? "#4ae6b8" : "#e64a5c",
                  color: g.connected ? "#464646" : "#fff",
                }}
              >
                <PanelTitle
                  titleStyle={{ color: g.connected ? "#464646" : "#fff" }}
                  title={g.description}
                ></PanelTitle>
                <PanelContent>
                  <Grid>
                    <Grid.Unit size={1 / 1}>
                      Last Data: {moment(g.last_data).format("HH:mm:ss")}
                    </Grid.Unit>
                  </Grid>
                </PanelContent>
              </Panel>
            </Grid.Unit>
          );
        })}
      </Grid> */}
      <Grid style={{ width: "100%" }}>
        {monitorMachines.map((m, index) => {
          return (
            <Grid.Unit size={1 / 5} style={{ marginTop: ".5em" }}>
              <Panel>
                <PanelTitle
                  title={m.machine || m.ip}
                  style={{ padding: ".5em" }}
                >
                  {m?.user && (
                    <Profile>
                      {m?.user?.first_name?.substring(0, 1)}
                      {m?.user?.last_name?.substring(0, 1)}
                    </Profile>
                  )}
                </PanelTitle>
                <PanelTitle
                  title={m?.game?.description}
                  style={{ padding: ".5em" }}
                ></PanelTitle>
                <PanelContent
                  style={{ flexDirection: "column", padding: ".5em" }}
                >
                  <Grid>
                    <Grid.Unit size={1 / 1}>
                      <Row>
                        <Connected connected={m?.game?.connected} />
                        OPTA{" "}
                        {m?.game?.last_data &&
                          `- Last data ${moment(m?.game?.last_data).fromNow()}`}
                      </Row>
                    </Grid.Unit>
                    <Grid.Unit size={1 / 1}>
                      <Row>
                        <Connected connected={m?.trio?.connected} />
                        Trio
                      </Row>
                    </Grid.Unit>
                  </Grid>
                </PanelContent>
              </Panel>
            </Grid.Unit>
          );
        })}
      </Grid>
    </Main>
  );
}

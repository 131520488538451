import React, { useContext } from "react";
import styled from "styled-components";
import LogsList from "../Logs/LogsList";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  return (
    <Main>
      <LogsList />
    </Main>
  );
}

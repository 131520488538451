import React, { useEffect, useState } from "react";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid grey;
  background-color: ${(props) => (props.selected ? "red" : undefined)};
`;

const Half = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
`;
export default function OPTAViewer() {
  const [json, setJson] = useState([]);
  const [selectedJson, setSelectedJson] = useState();
  useEffect(() => {
    fetch("./Vancouver Whitecaps vs Houston Dynamo.log")
      .then((res) => res.text())
      .then((data) => {
        let lines = data.split("\n");
        debugger;
        setJson(
          lines.map((l) => {
            try {
              let row = JSON.parse(l);
              let json = { message: row.message };
              try {
                json = JSON.parse(row.message);
              } catch (err) {}
              return { ...json, timestamp: row.timestamp };
            } catch (err) {
              return undefined;
            }
          })
        );
      });
  }, []);

  let data = json.filter((data) => {
    if (data?.content?.liveData?.matchDetails?.stats?.team) {
      return data?.content?.liveData?.matchDetails?.stats;
    }
  });

  return (
    <Main>
      <Half>
        {data.map((line, index) => {
          return (
            <Row
              selected={selectedJson == line}
              onClick={() => {
                setSelectedJson(line);
              }}
            >
              {line?.timestamp}
            </Row>
          );
        })}
      </Half>
      <Half>
        {selectedJson && (
          <>
            <pre>
              {JSON.stringify(
                selectedJson?.content.liveData.matchDetails.stats.team[0],
                undefined,
                2
              )}
            </pre>
            <pre>
              {selectedJson.content.liveData.matchDetails.stats.team.length >
                1 &&
                JSON.stringify(
                  selectedJson.content.liveData.matchDetails.stats.team[1],
                  undefined,
                  2
                )}
            </pre>
          </>
        )}
      </Half>
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Button, H1, H2, ScrollBar } from "../../Theme/Hyper";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import Kits from "../Team/Kits";
import Squad from "../Team/Squad";
import moment from "moment";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Input from "../UI/Form/Input";
import SavePanel from "../UI/Form/SavePanel";
import Grid from "styled-components-grid";

import { DataContext } from "../../contexts/DataContext";

import { UIContext } from "../../contexts/UIContext";

import BarLoader from "react-spinners/BarLoader";

import Modal from "../UI/Modal/Modal";

import TeamList from "./TeamList";

const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 250px;
  margin-top: 52px;
  margin-bottom: 52px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function Group({ groups, style, onUpdate = () => {} }) {
  let { groupId, seasonId } = useParams();
  const { getGroup, updateGroup, getSeason } = useContext(DataContext);
  const [savingState, setSavingState] = useState(0);
  const [update, setUpdate] = useState(false);
  const [group, setGroup] = useState();
  const [showTeamDialog, setShowTeamDialog] = useState(false);

  const [loading, setLoading] = useState(true);
  const [showActive, setShowActive] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState();
  const [selectedTeams, setSelectedTeams] = useState([]);

  const history = useHistory();

  useEffect(() => {
    updateData();
  }, [groupId]);

  useEffect(() => {
    getSeason(seasonId).then((data) => {
      setSelectedSeason(JSON.parse(JSON.stringify(data)));
    });
  }, [seasonId]);

  function updateData() {
    getGroup(groupId)?.then((data) => {
      setGroup(JSON.parse(JSON.stringify(data)));
      setSelectedTeams(data?.teams?.map((t) => t.team._id));
      setLoading(false);
    });
  }

  function save() {
    setSavingState("SAVING");

    updateGroup(group).then((data) => {
      onUpdate();
      setTimeout(() => {
        setSavingState("SAVED");
        setTimeout(() => {
          setSavingState(0);
        }, 1000);
      }, 1000);
    });
  }

  function saveTeams({ teams }) {
    let new_group = { ...group };

    new_group.teams = teams;

    updateGroup(new_group).then((data) => {
      updateData();
      onUpdate();
    });
  }

  return (
    <Main style={style}>
      {group && (
        <>
          <Grid>
            <Grid.Unit size={3 / 3} style={{ marginTop: "1em" }}>
              <Panel>
                <PanelTitle title="Group Details"></PanelTitle>
                <PanelContent>
                  <BarLoader loading={loading} color={"#36D7B7"} size={150} />
                  {!loading && (
                    <Grid style={{ width: "100%" }}>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 1 }}>
                        <Input
                          value={group.title || ""}
                          title="Title"
                          onChange={(e) => {
                            group.title = e.target.value;
                            setUpdate(!update);
                          }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={{ tablet: 1, desktop: 1 / 1 }}>
                        <Button
                          style={{ marginBottom: "1em", marginRight: "0px" }}
                          green
                          onClick={() => {
                            setShowTeamDialog(true);
                          }}
                        >
                          Select Teams
                        </Button>
                        Teams
                        <Table>
                          <thead>
                            <tr>
                              <TH>Team</TH>
                              <TH>Points</TH>
                              <TH>Played</TH>
                              <TH>Won</TH>
                              <TH>Lost</TH>
                              <TH>Drawn</TH>
                            </tr>
                          </thead>
                          {group?.teams?.map((t) => {
                            return (
                              <tr>
                                <td>{t?.team?.name}</td>
                                <td>{t?.points}</td>
                                <td>{t?.played}</td>
                                <td>{t?.won}</td>
                                <td>{t?.lost}</td>
                                <td>{t?.drawn}</td>
                              </tr>
                            );
                          })}
                        </Table>
                      </Grid.Unit>
                    </Grid>
                  )}
                </PanelContent>
                <SavePanel
                  showDelete={false}
                  state={savingState}
                  onDelete={() => {
                    setShowDelete(true);
                  }}
                  onSave={() => {
                    save();
                  }}
                  style={{ marginLeft: "auto" }}
                />
              </Panel>
            </Grid.Unit>
          </Grid>
        </>
      )}
      {showTeamDialog && (
        <Modal
          style={{ width: "70%", height: "700px" }}
          show={showTeamDialog}
          onCancel={() => {
            setShowTeamDialog(false);
          }}
        >
          <Panel>
            <PanelTitle title={"Select teams to add"}></PanelTitle>
            <PanelContent>
              <TeamList
                teams={selectedSeason?.teams}
                selectedTeams={selectedTeams}
                onTeamSelected={(team) => {
                  let newSelectedTeams = [...selectedTeams];

                  if (
                    newSelectedTeams?.findIndex((t) => t === team._id) === -1
                  ) {
                    newSelectedTeams.push(team?._id);
                  } else {
                    newSelectedTeams = newSelectedTeams.filter(
                      (t) => t !== team._id
                    );
                  }
                  setSelectedTeams(newSelectedTeams);
                }}
              />
            </PanelContent>
            <SavePanel
              showDelete={false}
              style={{ marginLeft: "auto" }}
              onSave={() => {
                saveTeams({ teams: selectedTeams });
                setShowTeamDialog(false);
              }}
              onCancel={() => {
                setShowTeamDialog(false);
              }}
            />
          </Panel>
        </Modal>
      )}
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import SelectUI from "../UI/Form/SelectUI";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
`;
export default function SeasonList({
  onSelect,
  competition,
  style,
  selectedSeason,
  seasons,
}) {
  return (
    <Main style={style}>
      <SelectUI
        value={{
          value: selectedSeason,
          label: selectedSeason && selectedSeason.name,
        }}
        options={
          competition &&
          seasons
            ?.filter((s) => s.competition._id === competition._id)
            ?.sort((a, b) => {
              return (
                parseInt(b.name.split("/")[0]) - parseInt(a.name.split("/")[0])
              );
            })
            ?.map((c) => {
              return { value: c, label: c.name };
            })
        }
        placeholder="Select Season"
        onChange={(season) => {
          onSelect(season);
        }}
      />
    </Main>
  );
}

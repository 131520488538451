import React from "react";
import Calendar from "react-calendar";
import styled from "styled-components";
import "../../../Calendar.css";
import moment from "moment";
import Select from "react-select";

const Main = styled.div`
  display: flex;
`;
const CalendarDiv = styled(Calendar)``;

const Time = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;
export default function KickOff({ date, time, onChangeDate, onChangeTime }) {
  return (
    <Main>
      <CalendarDiv
        minDetail={"month"}
        formatShortWeekday={(locale, date) => moment(date).format("dd")}
        prev2Label={null}
        next2Label={null}
        onChange={(val) => {
          onChangeDate(val);
        }}
        value={new Date(date.valueOf())}
      />
      <Time>
        <SelectContainer
          setSize={true}
          value={{
            value: time.hours(),
            label: time.hours().toString().padStart(2, "0"),
          }}
          options={new Array(24).fill(1).map((item, index) => {
            return {
              value: index + 1,
              label: (index + 1).toString().padStart(2, "0"),
            };
          })}
          onChange={(e) => {
            let newDate = moment(time).hours(e.value);
            onChangeTime(newDate);
          }}
          placeholder="HOUR"
        />
        :
        <SelectContainer
          setSize={true}
          value={{
            value: time.minutes(),
            label: time.minutes().toString().padStart(2, "0"),
          }}
          options={new Array(60 / 5).fill(1).map((item, index) => {
            let min = index * 5;
            return {
              value: min,
              label: min.toString().padStart(2, "0"),
            };
          })}
          onChange={(e) => {
            let newDate = moment(time).minutes(e.value);
            onChangeTime(newDate);
          }}
          placeholder="MINS"
        />
      </Time>
    </Main>
  );
}

import React, { useState, useEffect } from "react";
import Input from "../UI/Form/Input";
import Grid from "styled-components-grid";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";

import PlayerEdit from "../Player/PlayerEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import { Button } from "../../Theme/Hyper";
import { getRoles } from "@testing-library/react";

export default function UserEditor({ onSave, onCancel, userMember, onDelete }) {
  const [user, setUser] = useState();

  useEffect(() => {
    if (userMember) {
      setUser(userMember);
    } else {
      setUser({});
    }
  }, [userMember]);

  return (
    <Modal
      style={{ width: "70%" }}
      show={user}
      onCancel={() => {
        onCancel();
      }}
    >
      {user && (
        <Panel>
          <PanelTitle title={"Staff Edit"}></PanelTitle>
          <PanelContent style={{ flexDirection: "column" }}>
            <Grid>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={user?.username}
                  title="Username"
                  onChange={(e) => {
                    setUser({ ...user, username: e.currentTarget.value });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={user?.password}
                  title="Password"
                  onChange={(e) => {
                    setUser({ ...user, password: e.currentTarget.value });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={user?.first_name}
                  title="First Name"
                  onChange={(e) => {
                    setUser({ ...user, first_name: e.currentTarget.value });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={user?.last_name}
                  title="Last Name"
                  onChange={(e) => {
                    setUser({ ...user, last_name: e.currentTarget.value });
                  }}
                />
              </Grid.Unit>
            </Grid>
            <Grid>Roles</Grid>
            <Grid>
              <Grid.Unit size={1 / 8}>
                <Button
                  green={user?.roles?.findIndex((i) => i === "admin") > -1}
                  onClick={(e) => {
                    let newRoles = [...(user.roles || [])];
                    if (newRoles.findIndex((i) => i === "admin") > -1) {
                      newRoles = newRoles.filter((i) => i !== "admin");
                    } else {
                      newRoles.push("admin");
                    }
                    setUser({ ...user, roles: newRoles });
                  }}
                >
                  Admin
                </Button>
              </Grid.Unit>
              <Grid.Unit size={1 / 8}>
                <Button
                  green={user?.roles?.findIndex((i) => i === "support") > -1}
                  onClick={(e) => {
                    let newRoles = [...(user.roles || [])];
                    if (newRoles.findIndex((i) => i === "support") > -1) {
                      newRoles = newRoles.filter((i) => i !== "support");
                    } else {
                      newRoles.push("support");
                    }
                    setUser({ ...user, roles: newRoles });
                  }}
                >
                  Support
                </Button>
              </Grid.Unit>
              <Grid.Unit size={1 / 8}>
                <Button
                  green={user?.roles?.findIndex((i) => i === "graphics") > -1}
                  onClick={(e) => {
                    let newRoles = [...(user.roles || [])];
                    if (newRoles.findIndex((i) => i === "graphics") > -1) {
                      newRoles = newRoles.filter((i) => i !== "graphics");
                    } else {
                      newRoles.push("graphics");
                    }
                    setUser({ ...user, roles: newRoles });
                  }}
                >
                  Graphics
                </Button>
              </Grid.Unit>
              <Grid.Unit size={1 / 8}>
                <Button
                  green={user?.roles?.findIndex((i) => i === "stats") > -1}
                  onClick={(e) => {
                    let newRoles = [...(user.roles || [])];
                    if (newRoles.findIndex((i) => i === "stats") > -1) {
                      newRoles = newRoles.filter((i) => i !== "stats");
                    } else {
                      newRoles.push("stats");
                    }
                    setUser({ ...user, roles: newRoles });
                  }}
                >
                  Stats
                </Button>
              </Grid.Unit>
            </Grid>
          </PanelContent>

          <SavePanel
            showDelete={userMember.username}
            onDelete={() => {
              onDelete(user);
            }}
            onSave={() => {
              onSave(user);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}

import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  align-items: center;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 700;
  color: #343a40;
  margin-bottom: 0;
`;

const Right = styled.div`
  margin-left: auto;
`;
export default function PanelTitle({ titleStyle = {}, children, title }) {
  return (
    <Main>
      <Title style={titleStyle}>{title}</Title>
      <Right>{children}</Right>
    </Main>
  );
}

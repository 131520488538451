import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";

export default function CountryEdit({ country, onChange }) {
  function updateCountry(country) {
    onChange(country);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={country.code}
          title="Code"
          onChange={(e) => {
            let newCountry = { ...country, code: e.target.value };
            updateCountry(newCountry);
          }}
        />
      </Grid.Unit>
    </Grid>
  );
}

import React, { useContext, useState } from "react";
import styled from "styled-components";
import { SocketContext } from "../../contexts/SocketContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 40%;
  justify-content: center;
  padding: 0.5em;
  background-color: ${(props) => (props.selected ? "#293b52" : "")};
  color: ${(props) => (props.selected ? "#fff" : "")};
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
  background-color: ${(props) =>
    props.hover ? "#d31c42" : props.total ? "#f7f7f7" : ""};
  color: ${(props) => (props.hover ? "white" : "")};
  font-weight: ${(props) => (props.total ? "bold" : "")};
`;
const Bar = styled.div`
  display: flex;
  width: 100%;
`;
const InnerBar = styled.div`
  display: flex;
`;
const Bars = styled.div`
  display: flex;
  width: 100%;
`;
const Split = styled.div`
  display: flex;
  width: 1%;
`;

export default function Stat({ category, stat, homeColour, awayColour }) {
  const { sendData, game } = useContext(SocketContext);
  const [hover, setHover] = useState();
  let homePercentage = (stat.home / (stat.home + stat.away)) * 100 || 0;
  let awayPercentage = (stat.away / (stat.home + stat.away)) * 100 || 0;

  let period =
    game.live_data &&
    game.live_data.periods &&
    game.live_data.periods.sort((a, b) => b.number - a.number)[0];

  if (!period.kick_off) {
    period.number = 4;
  }

  return (
    <Main>
      <StatDiv>
        <Value
          hover={hover === 0}
          onMouseOver={() => {
            setHover(0);
          }}
          onMouseOut={() => {
            setHover();
          }}
        >
          {category !== "Possession" && period.number > 1 && stat.homeFH}
          {category !== "Possession" && period.number == 1 && stat.home}
          {category === "Possession" &&
            period.number == 1 &&
            Math.round(stat.home || 100 - stat.away || 0) + "%"}
          {category === "Possession" &&
            period.number != 1 &&
            Math.round(stat.homeFH) + "%"}
          {category === "Passing accuracy" && "%"}
        </Value>
        <Value
          hover={hover === 1}
          onMouseOver={() => {
            setHover(1);
          }}
          onMouseOut={() => {
            setHover();
          }}
        >
          {category !== "Possession" &&
            category !== "Passing accuracy" &&
            period.number > 2 &&
            stat.homeSH}
          {category !== "Possession" &&
            category !== "Passing accuracy" &&
            period.number == 2 &&
            stat.home - stat.homeFH}
          {category === "Possession" &&
            period.number > 1 &&
            Math.round(stat.homeSH) + "%"}
          {period.number > 1 &&
            category === "Passing accuracy" &&
            stat.homeSH + "%"}
        </Value>
        <Value
          total
          hover={hover === 2}
          onMouseOver={() => {
            setHover(2);
          }}
          onMouseOut={() => {
            setHover();
          }}
        >
          {category !== "Possession" && stat.home}
          {category === "Possession" &&
            Math.round(stat.home || 100 - stat.away || 0) + "%"}
          {category === "Passing accuracy" && "%"}
        </Value>
        <Category
          selected={game.stats?.game?.graphics?.indexOf(category) > -1}
          onClick={() => {
            let newData = [...(game?.stats?.game?.graphics || [])];
            if (game.stats?.game?.graphics?.indexOf(category) > -1) {
              newData = newData.filter((f) => f !== category);
            } else {
              newData.push(category);
            }

            sendData({
              type: "update_game",
              _id: game._id,
              variable: "stats.game.graphics",
              data: newData,
            });
          }}
        >
          {category}
        </Category>
        <Value
          hover={hover === 0}
          onMouseOver={() => {
            setHover(0);
          }}
          onMouseOut={() => {
            setHover();
          }}
        >
          {category !== "Possession" && period.number > 1 && stat.awayFH}
          {category !== "Possession" && period.number == 1 && stat.away}
          {category === "Possession" &&
            period.number == 1 &&
            Math.round(stat.away || 100 - stat.home || 0) + "%"}
          {category === "Possession" &&
            period.number != 1 &&
            100 - Math.round(stat.homeFH) + "%"}
          {category === "Passing accuracy" && "%"}
        </Value>
        <Value
          hover={hover === 1}
          onMouseOver={() => {
            setHover(1);
          }}
          onMouseOut={() => {
            setHover();
          }}
        >
          {category !== "Possession" &&
            category !== "Passing accuracy" &&
            period.number > 2 &&
            stat.awaySH}
          {category !== "Possession" &&
            category !== "Passing accuracy" &&
            period.number == 2 &&
            stat.away - stat.awayFH}
          {category === "Possession" &&
            period.number > 1 &&
            100 - Math.round(stat.homeSH) + "%"}
          {period.number > 1 &&
            category === "Passing accuracy" &&
            stat.awaySH + "%"}
        </Value>
        <Value
          total
          hover={hover === 2}
          onMouseOver={() => {
            setHover(2);
          }}
          onMouseOut={() => {
            setHover();
          }}
        >
          {category !== "Possession" && stat.away}
          {category === "Possession" &&
            Math.round(stat.away || 100 - stat.home || 0) + "%"}
          {category === "Passing accuracy" && "%"}
        </Value>
      </StatDiv>
      <Bars>
        <Bar style={{ justifyContent: "flex-end" }}>
          <InnerBar
            style={{
              width: homePercentage + "%",
              height: "2px",
              backgroundColor: homeColour,
            }}
          />
        </Bar>
        <Split />
        <Bar>
          <InnerBar
            style={{
              width: awayPercentage + "%",
              height: "2px",
              backgroundColor: awayColour,
            }}
          />
        </Bar>
      </Bars>
    </Main>
  );
}

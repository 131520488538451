import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function TransferList() {
  const { getTransfer } = useContext(DataContext);
  const [transfers, setTransfer] = useState();

  useEffect(() => {
    getTransfer({}).then((data) => {
      setTransfer(data);
    });
  }, []);
  return (
    <Panel style={{ marginTop: "1em" }}>
      <PanelTitle title={"Transfer List"} />
      <PanelContent>
        <Table>
          <thead>
            <tr>
              <TH>Player</TH>
              <TH>Moved From</TH>
              <TH>Moved To</TH>
              <TH>Start Date</TH>
              <TH>Announced Date</TH>
            </tr>
          </thead>
          <tbody>
            {transfers &&
              transfers.map((transfer) => {
                return (
                  <tr>
                    <td>
                      {transfer.player.first_name} {transfer.player.last_name}
                    </td>
                    <td>{transfer.moved_from}</td>
                    <td>{transfer.moved_to}</td>
                    <td>{transfer.start_date}</td>
                    <td>{transfer.announced_date}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </PanelContent>
    </Panel>
  );
}

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Select from "react-select";
import { GoCalendar } from "react-icons/go";
import moment from "moment";
import Lineup from "../Lineup/Lineup";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  border-bottom: 1px solid #dadde1;
  box-sizing: border-box;
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 70px;
`;
const TeamSelect = styled.div`
  display: flex;
  margin-top: 1em;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;
const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #dadde1;
  box-sizing: border-box;
  width: 67.7%;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  background: white;
  align-items: center;
`;
const KickOff = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
`;

const Property = styled.div`
  display: flex;
  border-top: 1px solid #dadde1;
  width: 100%;
  margin-top: 1em;
  padding: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const ActionPanel = styled.div`
  display: flex;
  border-top: 1px solid #dadde1;
  width: 100%;
  margin-top: 1em;
  padding: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const TeamName = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;
`;

const Label = styled.div`
  display: flex;
  font-weight: 300;
  margin-right: 1em;
`;
const Value = styled.div`
  display: flex;
  align-items: center;
  position: relative;
      justify-content: center;width: 100%;
}
`;
const Buttons = styled.div`
  margin-left: auto;
  display: flex;
`;

const Button = styled.div`
  display: flex;
  margin-left: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) => (props.green ? "#14ec8e" : "")};
  color: ${(props) => (props.green ? "#fff" : "")};
`;

const GoCalendarContainer = styled(GoCalendar)`
  display: flex;
  align-items: center;
  margin-right: 1em;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.highlightColour};
  }
`;

const DateDiv = styled.div`
  display: flex;
  margin-right: 1em;
`;
const Time = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
`;
const Lineups = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
const Kits = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
`;

const Kit = styled.div`
  display: flex;
  margin: 1em;
`;

const KitColour = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 0.5em;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;
const TeamText = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;
export default function Game({ fixture }) {
  const { teams, players, updateGame } = useContext(DataContext);
  const [homeTeam, setHomeTeam] = useState();
  const [awayTeam, setAwayTeam] = useState();
  const [clock, setClock] = useState();

  return (
    <>
      {fixture && homeTeam && awayTeam && (
        <Main>
          <Content>
            <ActionPanel>
              <TeamName>{homeTeam.name}</TeamName>
              {fixture.actual_kick_off && clock}
              {!fixture.actual_kick_off && (
                <Button
                  green
                  onClick={() => {
                    updateGame({
                      uuid: fixture.uuid,
                      data: { actual_kick_off: moment().valueOf() },
                    });
                  }}
                >
                  KICK OFF
                </Button>
              )}
              <Button
                green
                onClick={() => {
                  updateGame({
                    uuid: fixture.uuid,
                    data: { actual_kick_off: null },
                  });
                }}
              >
                RESET CLOCK
              </Button>
              <TeamName>{awayTeam.name}</TeamName>
            </ActionPanel>
            <Property>
              <Row>
                <Kits>
                  {homeTeam.teamKits.map((kit) => {
                    return (
                      <Kit>
                        <KitColour style={{ backgroundColor: kit.colour1 }}>
                          <TeamText style={{ color: kit.colour2 }}>
                            {homeTeam.symid}
                          </TeamText>
                        </KitColour>
                      </Kit>
                    );
                  })}
                </Kits>
                <Kits>
                  {awayTeam.teamKits.map((kit) => {
                    return (
                      <Kit>
                        <KitColour style={{ backgroundColor: kit.colour1 }}>
                          <TeamText style={{ color: kit.colour2 }}>
                            {awayTeam.symid}
                          </TeamText>
                        </KitColour>
                      </Kit>
                    );
                  })}
                </Kits>
              </Row>
            </Property>
            <Property>
              <Label>Lineups</Label>

              <Lineups>
                <Lineup
                  number_of_players_on_pitch={
                    fixture?.competition?.competition_type === "beach" ? 5 : 11
                  }
                  number_of_players_in_squad={
                    fixture?.competition?.competition_type === "beach" ? 12 : 16
                  }
                  kickedOff={fixture.actual_kick_off > 0}
                  team={homeTeam}
                  lineup={fixture.home_team_lineup}
                  players={players}
                  updateLineup={(team) => {
                    updateGame({
                      uuid: fixture.uuid,
                      data: {
                        home_team_lineup: team
                          .filter((player) => player.playing)
                          .map((player) => {
                            return {
                              uuid: player.uuid,
                              captain: player.captain || false,
                              playing: player.playing,
                              sub: player.sub || false,
                              yellowCard: player.yellowCard || false,
                              yellowDblCard: player.yellowDblCard || false,
                              redCard: player.redCard || false,
                            };
                          }),
                      },
                    });
                  }}
                />
                <Lineup
                  number_of_players_on_pitch={
                    fixture?.competition?.competition_type === "beach" ? 5 : 11
                  }
                  number_of_players_in_squad={
                    fixture?.competition?.competition_type === "beach" ? 12 : 16
                  }
                  kickedOff={fixture.actual_kick_off > 0}
                  team={awayTeam}
                  players={players}
                  lineup={fixture.away_team_lineup}
                  updateLineup={(team) => {
                    updateGame({
                      uuid: fixture.uuid,
                      data: {
                        away_team_lineup: team
                          .filter((player) => player.playing)
                          .map((player) => {
                            return {
                              uuid: player.uuid,
                              captain: player.captain || false,
                              playing: player.playing,
                              sub: player.sub || false,
                            };
                          }),
                      },
                    });
                  }}
                />
              </Lineups>
            </Property>
          </Content>
        </Main>
      )}
    </>
  );
}

import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import SeasonsList from "../Season/SeasonsList";

const Main = styled.div`
  background-color: #fff;
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function Seasons() {
  return (
    <Main>
      <SeasonsList />
    </Main>
  );
}

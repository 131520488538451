import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";

export default function SettingEdit({ setting, onChange }) {
  function updateSetting(setting) {
    onChange(setting);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={setting.name}
          title="Name"
          onChange={(e) => {
            let newSetting = { ...setting, name: e.target.value };
            updateSetting(newSetting);
          }}
        />
        <Input
          value={setting.value}
          title="Value"
          onChange={(e) => {
            let newSetting = { ...setting, value: e.target.value };
            updateSetting(newSetting);
          }}
        />
      </Grid.Unit>
    </Grid>
  );
}

import React from "react";
import styled from "styled-components";

const Main = styled.div`
  width: 45%;
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  align-items: center;
  margin-left: 2em;
  margin-right: 2em;
`;

export default function HeroTeam({ children, style }) {
  return <Main style={style}>{children}</Main>;
}

import React from "react";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import styled from "styled-components";

const Table = styled.table`
  width: 45%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;

  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
  }

  > tbody > tr > td:nth-child(1) {
    width: 56px;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function AutoCB({
  game,
  home_team,
  away_team,
  homeSquad,
  awaySquad,
  variable,
  text,
}) {
  if (!game || !game?.stats) {
    return <></>;
  }

  function most() {
    let players = [
      ...(game?.stats?.home_player_stats || []),
      ...(game?.stats?.away_player_stats || []),
    ].sort((a, b) => {
      return parseInt(b[variable] || -999) - parseInt(a[variable] || -999);
    });
    return players.slice(0, 5);
  }

  function least() {
    let players = [
      ...(game?.stats?.home_player_stats || []),
      ...(game?.stats?.away_player_stats || []),
    ].sort((a, b) => {
      return parseInt(a[variable] || 999) - parseInt(b[variable] || 999);
    });
    return players.slice(0, 5);
  }

  return (
    <Panel>
      <PanelTitle title={text}></PanelTitle>
      <PanelContent
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Table>
          <thead>
            <tr>
              <TH colSpan={3}>Most {text} this match</TH>
            </tr>
          </thead>
          <tbody>
            {homeSquad?.length > 0 &&
              awaySquad?.length > 0 &&
              most().map((player) => {
                let p;
                try {
                  p = homeSquad?.find(
                    (pl) => pl.opta_ID == player.player_opta_ID
                  );

                  if (!p) {
                    p = awaySquad?.find(
                      (pl) => pl.opta_ID == player.player_opta_ID
                    );
                    p.team = away_team;
                  } else {
                    p.team = home_team;
                  }
                } catch (err) {}
                return (
                  <tr>
                    <td>{p?.team?.code}</td>
                    <td>
                      {(p?.first_name !== "" ? p?.first_name + " " : "") +
                        p?.last_name}
                    </td>
                    <td>{player[variable]}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Table>
          <thead>
            <tr>
              <TH colSpan={3}>Fewest {text} this match</TH>
            </tr>
          </thead>
          <tbody>
            {homeSquad?.length > 0 &&
              awaySquad?.length > 0 &&
              least().map((player) => {
                let p;
                try {
                  p = homeSquad?.find(
                    (pl) => pl.opta_ID == player.player_opta_ID
                  );

                  if (!p) {
                    p = awaySquad?.find(
                      (pl) => pl.opta_ID == player.player_opta_ID
                    );
                    p.team = game?.away_team;
                  } else {
                    p.team = game?.home_team;
                  }
                } catch (err) {}
                return (
                  <tr>
                    <td>{p?.team?.code}</td>
                    <td>
                      {(p?.first_name !== "" ? p?.first_name + " " : "") +
                        p?.last_name}
                    </td>
                    <td>{player[variable]}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </PanelContent>
    </Panel>
  );
}

import React from "react";
import styled from "styled-components";

const Main = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  height: 100%;
`;

export default function Panel({ children, style }) {
  return <Main style={style}>{children}</Main>;
}

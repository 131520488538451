import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import PlayerList from "../Player/PlayerList";
import PanelTitle from "../UI/Panel/PanelTitle";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import Lineup from "../Lineup/Lineup";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import Pagination from "../UI/Pagination/Pagination";
import PlayerEditor from "../Modals/PlayerEditor";

const Main = styled.div`
  margin-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  const { getPlayersBySearch, getSquads, getTeams } = useContext(DataContext);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [players, setPlayers] = useState();
  const [squads, setSquads] = useState();
  const [teams, setTeams] = useState();
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(500);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getData();
  }, [page]);

  function getData() {
    getTeams({})
      .then((data) => {
        setTeams(data);
      })
      .then(() => {
        // return getSquads().then((data) => {
        //   setSquads(data);
        // });
      })
      .then(() => {
        getPlayersBySearch({ search: "", count: true })
          .then((data) => {
            setCount(data);
          })
          .then(() => {
            getPlayersBySearch({
              search: "",
              count: false,
              limit: limit,
              page: (page - 1) * limit,
            }).then((data) => {
              setPlayers(data);
            });
          });
      });
  }

  return (
    <Main>
      <Grid>
        <Grid.Unit size={1 / 1}>
          <Panel>
            <PanelTitle title="Search"></PanelTitle>
            <PanelContent>
              <Input value={""} placeholder={"Filter by First Name..."} />
              <Input value={""} placeholder={"Filter by Last Name..."} />
              <Input value={""} placeholder={"Filter by Team..."} />
            </PanelContent>
          </Panel>
        </Grid.Unit>
        <Grid.Unit size={1 / 1}>
          <Panel style={{ marginTop: "1em" }}>
            <PanelTitle title={"Player List"} />
            <PanelContent>
              <Pagination
                page={page}
                count={count}
                limit={limit}
                onChange={(page) => {
                  setPage(page);
                }}
              />
            </PanelContent>
            <PanelContent style={{ flexDirection: "column" }}>
              {count && <div>Total Players: {count}</div>}
              {players && (
                <Lineup
                  showTeam
                  squad={players.map((player) => {
                    return player;
                    let squadPlayer = squads.find(
                      (s) => s.player === player._id
                    );
                    let t = squadPlayer && squadPlayer.team;
                    let team =
                      t &&
                      teams.find(
                        (t) =>
                          t._id ===
                          squads.find((s) => s.player === player._id).team
                      );

                    return { ...player, team: team };
                  })}
                  selectedPlayer={selectedPlayer}
                  selectPlayer={(player) => {
                    setSelectedPlayer(player);
                  }}
                />
              )}
            </PanelContent>
            <PanelContent>
              <Pagination
                page={page}
                count={count}
                limit={limit}
                onChange={(page) => {
                  setPage(page);
                }}
              />
            </PanelContent>
          </Panel>
        </Grid.Unit>
      </Grid>
      <PlayerEditor
        player={selectedPlayer}
        selectPlayer={(p) => {
          setSelectedPlayer(p);
          getData();
        }}
        onCancel={() => {
          setSelectedPlayer();
        }}
      />
    </Main>
  );
}

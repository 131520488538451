import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTable } from 'react-table'
import styled from 'styled-components';

const TH = styled.th`
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;

> div {
  transform: ${props => props.rotate ? 'rotate(-90deg)' : ''};
    /* Magic Numbers */
    /* translate(25px, 51px) */
    /* 45 is really 360 - 45 */
   
  width: 30px;
}
> div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}
`

const TD = styled.td`
text-align: ${props => props.center ? 'center' : 'left'};
border: 1px solid #ccc;
`

export default function PlayerStats({ game, homeSquad, awaySquad }) {
    const [data, setData] = useState([])

    useEffect(() => {
        if (homeSquad.length > 0 && game) {
            setData(game?.opta_player_stats?.home?.map(player => {
                let obj = {
                    col1: (homeSquad?.find(p => p.opta_ID === player?.player)?.first_name + ' ' || '') + homeSquad?.find(p => p.opta_ID === player?.player)?.last_name || '',
                }

                player.stats && player.stats.forEach((stat) => {
                    obj[stat.type] = stat.value;
                })

                return obj
            }) || [])
        }
    }, [homeSquad, game])



    const columns = React.useMemo(
        () => [
            {
                Header: 'Player Name',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Minutes Played',
                accessor: 'minsPlayed',
            },
            {
                Header: 'Total Clearance',
                accessor: 'totalClearance',
            },
            {
                Header: 'aerialWon',
                accessor: 'aerialWon',
            },
            {
                Header: 'successfulFiftyFifty',
                accessor: 'successfulFiftyFifty',
            },
            {
                Header: 'attemptsConcededIbox',
                accessor: 'attemptsConcededIbox',
            },
            {
                Header: 'possLostCtrl',
                accessor: 'possLostCtrl',
            },
            {
                Header: 'accuratePass',
                accessor: 'accuratePass',
            },
            {
                Header: 'accurateChippedPass',
                accessor: 'accurateChippedPass',
            },
            {
                Header: 'longPassOwnToOppSuccess',
                accessor: 'longPassOwnToOppSuccess',
            },
            {
                Header: 'backwardPass',
                accessor: 'backwardPass',
            },
            {
                Header: 'fwdPass',
                accessor: 'fwdPass',
            },
            {
                Header: 'successfulPutThrough',
                accessor: 'successfulPutThrough',
            },
            {
                Header: 'attemptedTackleFoul',
                accessor: 'attemptedTackleFoul',
            },
            {
                Header: 'successfulFinalThirdPasses',
                accessor: 'successfulFinalThirdPasses',
            },
            {
                Header: 'duelLost',
                accessor: 'duelLost',
            },
            {
                Header: 'touches',
                accessor: 'touches',
            },
            {
                Header: 'possLostAll',
                accessor: 'possLostAll',
            },
            {
                Header: 'successfulOpenPlayPass',
                accessor: 'successfulOpenPlayPass',
            },
            {
                Header: 'attemptsConcededObox',
                accessor: 'attemptsConcededObox',
            },
            {
                Header: 'blockedPass',
                accessor: 'blockedPass',
            },
            {
                Header: 'openPlayPass',
                accessor: 'openPlayPass',
            },
            {
                Header: 'accurateLongBalls',
                accessor: 'accurateLongBalls',
            },
            {
                Header: 'totalPass',
                accessor: 'totalPass',
            },
            {
                Header: 'totalChippedPass',
                accessor: 'totalChippedPass',
            },
            {
                Header: 'passesLeft',
                accessor: 'passesLeft',
            },
            {
                Header: 'passesRight',
                accessor: 'passesRight',
            },
            {
                Header: 'accurateBackZonePass',
                accessor: 'accurateBackZonePass',
            },
            {
                Header: 'leftsidePass',
                accessor: 'leftsidePass',
            },

        ],
        []
    )
    const tableInstance = useTable({ columns, data })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (
        // apply the table props
        <table style={{ width: '100%' }}{...getTableProps()}>
            <thead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map(column => {

                                    return (

                                        // Apply the header cell props
                                        <TH {...column.getHeaderProps()} rotate={column.Header !== 'Player Name'}>
                                            <div

                                            ><span>{// Render the header
                                                column.render('Header')}</span></div>
                                        </TH>
                                    )
                                })}
                        </tr>
                    ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                    rows.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            // Apply the row props
                            <tr {...row.getRowProps()}>
                                {// Loop over the rows cells
                                    row.cells.map(cell => {
                                        // Apply the cell props

                                        return (
                                            <TD center={cell.column.Header !== 'Player Name' ? true : false} {...cell.getCellProps()}>
                                                {// Render the cell contents
                                                    cell.render('Cell')}
                                            </TD>
                                        )
                                    })}
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}

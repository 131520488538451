import React from "react";
import Select from "react-select";
import styled from "styled-components";

const SelectContainer = styled(Select)`
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
`;
const Label = styled.label`
  display: flex;
  font-size: 0.8em;
  margin-bottom: 0.2em;
`;
export default function SelectUI({
  options,
  value,
  onChange,
  placeholder,
  title,
}) {
  return (
    <Main>
      {title && <Label>{title}</Label>}

      <SelectContainer
        setSize={true}
        value={value}
        options={options}
        onChange={(e) => {
          onChange(e.value);
        }}
        placeholder={placeholder}
      />
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import UsersList from "../Users/UsersList";
import UserEditor from "../Modals/UserEditor";
const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

const Actions = styled.div`
  display: flex;
  margin: 1em;
`;

export default function () {
  const [editUser, setEditUser] = useState();
  const { getUser, updateUser, deleteUser } = useContext(DataContext);
  const [users, setUsers] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getUser({}).then((data) => {
      setUsers(data);
    });
  }
  return (
    <Main>
      <Actions>
        <Button green onClick={() => setEditUser({})}>
          + New User
        </Button>
      </Actions>
      <UsersList
        users={users}
        onSelect={(user) => {
          setEditUser(user);
        }}
      />
      {editUser && (
        <UserEditor
          userMember={editUser}
          onCancel={() => {
            setEditUser();
          }}
          onSave={(data) => {
            updateUser({ data: data }).then(() => {
              setEditUser();
              getData();
            });
          }}
          onDelete={(user) => {
            deleteUser({ data: user }).then(() => {
              setEditUser();
              getData();
            });
          }}
        />
      )}
    </Main>
  );
}

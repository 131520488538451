import React from "react";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import styled from "styled-components";

const Table = styled.table`
  width: 45%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
  margin-right: 1em;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
  }

  > tbody > tr > td:nth-child(1) {
    width: 56px;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function MatchFacts({ game, home_team, away_team, text }) {
  if (!game || !game?.stats) {
    return <></>;
  }

  let touchesInOppBox = {
    home: game?.opta_team_stats?.home?.find(
      (stat) => stat.type === "touchesInOppBox"
    ),
    away: game?.opta_team_stats?.away?.find(
      (stat) => stat.type === "touchesInOppBox"
    ),
  };
  debugger;
  let period =
    game.live_data &&
    game.live_data.periods &&
    game.live_data.periods.sort((a, b) => b.number - a.number)[0];

  return (
    <Panel>
      <PanelTitle title={text}></PanelTitle>
      <PanelContent
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Table>
          <thead>
            <tr>
              <TH>This match</TH>
              <TH>{home_team?.code}</TH>
              <TH>{away_team?.code}</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Shots</td>
              <td>{game?.stats?.home_team?.totalScoringAtt || "0"}</td>
              <td>{game?.stats?.away_team?.totalScoringAtt || "0"}</td>
            </tr>
            <tr>
              <td>On Target</td>
              <td>{game?.stats?.home_team?.ontargetScoringAtt || "0"}</td>
              <td>{game?.stats?.away_team?.ontargetScoringAtt || "0"}</td>
            </tr>
            <tr>
              <td>Touches in opp. box</td>
              <td>{touchesInOppBox?.home?.value || "0"}</td>
              <td>{touchesInOppBox?.away?.value || "0"}</td>
            </tr>
            <tr>
              <td>Possession</td>
              <td>
                {(Math.round(game?.stats?.home_team?.possessionPercentage) ||
                  0) + "%"}
              </td>
              <td>
                {100 -
                  (Math.round(game?.stats?.home_team?.possessionPercentage) ||
                    0) +
                  "%"}
              </td>
            </tr>
          </tbody>
        </Table>
        {period.number > 1 && (
          <Table>
            <thead>
              <tr>
                <TH>1st Half</TH>
                <TH>{home_team?.code}</TH>
                <TH>{away_team?.code}</TH>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Shots</td>
                <td>
                  {game?.opta_team_stats?.home?.find(
                    (stat) => stat.type === "totalScoringAtt"
                  )?.fh || "0"}
                </td>
                <td>
                  {game?.opta_team_stats?.away?.find(
                    (stat) => stat.type === "totalScoringAtt"
                  )?.fh || "0"}
                </td>
              </tr>
              <tr>
                <td>On Target</td>
                <td>
                  {game?.opta_team_stats?.home?.find(
                    (stat) => stat.type === "ontargetScoringAtt"
                  )?.fh || "0"}
                </td>
                <td>
                  {game?.opta_team_stats?.away?.find(
                    (stat) => stat.type === "ontargetScoringAtt"
                  )?.fh || "0"}
                </td>
              </tr>
              <tr>
                <td>Touches in opp. box</td>

                <td>{touchesInOppBox?.home?.fh || "0"}</td>
                <td>{touchesInOppBox?.away?.fh || "0"}</td>
              </tr>
              <tr>
                <td>Possession</td>
                <td>
                  {Math.round(
                    game?.opta_team_stats?.home?.find(
                      (stat) => stat.type === "possessionPercentage"
                    )?.fh || 0
                  ) + "%"}
                </td>
                <td>
                  {100 -
                    Math.round(
                      game?.opta_team_stats?.home?.find(
                        (stat) => stat.type === "possessionPercentage"
                      )?.fh || 0
                    ) +
                    "%"}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        {period.number > 1 && (
          <Table>
            <thead>
              <tr>
                <TH>2nd Half</TH>
                <TH>{home_team?.code}</TH>
                <TH>{away_team?.code}</TH>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Shots</td>
                <td>
                  {game?.stats?.home_team?.totalScoringAtt -
                    (game?.opta_team_stats?.home?.find(
                      (stat) => stat.type === "totalScoringAtt"
                    )?.fh || 0) || "0"}
                </td>
                <td>
                  {game?.stats?.away_team?.totalScoringAtt -
                    (game?.opta_team_stats?.away?.find(
                      (stat) => stat.type === "totalScoringAtt"
                    )?.fh || 0) || "0"}
                </td>
              </tr>
              <tr>
                <td>On Target</td>
                <td>
                  {game?.stats?.home_team?.ontargetScoringAtt -
                    (game?.opta_team_stats?.home?.find(
                      (stat) => stat.type === "ontargetScoringAtt"
                    )?.fh || 0) || "0"}
                </td>
                <td>
                  {game?.stats?.away_team?.ontargetScoringAtt -
                    (game?.opta_team_stats?.away?.find(
                      (stat) => stat.type === "ontargetScoringAtt"
                    )?.fh || 0) || "0"}
                </td>
              </tr>
              <tr>
                <td>Touches in opp. box</td>
                <td>{touchesInOppBox?.home?.sh || "0"}</td>
                <td>{touchesInOppBox?.away?.sh || "0"}</td>
              </tr>
              <tr>
                <td>Possession</td>
                <td>
                  {Math.round(
                    game?.opta_team_stats?.home?.find(
                      (stat) => stat.type === "possessionPercentage"
                    )?.sh || 0
                  ) + "%"}
                </td>
                <td>
                  {100 -
                    Math.round(
                      game?.opta_team_stats?.home?.find(
                        (stat) => stat.type === "possessionPercentage"
                      )?.sh || 0
                    ) +
                    "%"}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </PanelContent>
    </Panel>
  );
}

import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import CompetitionList from "../Competition/CompetitionList";
import SeasonList from "../Season/SeasonList";
import { UIContext } from "../../contexts/UIContext";
import { Button, ScrollBar } from "../../Theme/Hyper";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";

import SavePanel from "../UI/Form/SavePanel";
const Main = styled.div`
  width: 250px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;

  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;
const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;

const Scroll = styled(ScrollBar)`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
`;
export default function GroupList({ groups }) {
  const history = useHistory();
  const { seasonId, groupId } = useParams();
  const [groupsArray, setGroupsArray] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [selectedSeason, setSelectedSeason] = useState();
  const [seasons, setSeasons] = useState([]);
  const { createGroup, addTeamToGroup, getSeason, getGroupsBySeason } =
    useContext(DataContext);
  const [selectedGroups, setSelectedGroup] = useState([]);

  useEffect(() => {
    getSeason().then((data) => {
      setSeasons(data);
      if (selectedCompetition) {
        let newSeason = data
          ?.filter((s) => s.competition._id === selectedCompetition._id)
          ?.sort((a, b) => {
            return (
              parseInt(b.name.split("/")[0]) - parseInt(a.name.split("/")[0])
            );
          })[0];
        setSelectedSeason(newSeason);
      }
    });
  }, [selectedCompetition, groups]);

  useEffect(() => {
    getGroups();
  }, [selectedSeason, groups]);

  useEffect(() => {
    if (seasonId && seasons?.length) {
      setSelectedSeason(seasons?.find((s) => s._id === seasonId));
      setSelectedCompetition(
        seasons?.find((s) => s._id === seasonId)?.competition
      );
    }
  }, [seasonId, seasons]);

  const getGroups = useCallback(() => {
    getGroupsBySeason({ seasonId: selectedSeason?._id })
      ?.then((data) => {
        setGroupsArray(data);
      })
      ?.catch((err) => {
        console.log(err);
      });
  }, [selectedSeason, groups]);

  const createNewGroup = useCallback(() => {
    createGroup({
      group: {
        season: selectedSeason._id,
        competition: selectedCompetition._id,
        title: "Group " + (groupsArray.length + 1),
      },
    }).then((group) => {
      setSelectedGroup(group._id);
    });
  }, [selectedSeason, groupsArray, addTeamToGroup, createGroup, history]);

  return (
    <Main>
      <CompetitionList
        selectedCompetition={selectedCompetition}
        onSelect={setSelectedCompetition}
        style={{ margin: "1em" }}
      />
      <SeasonList
        selectedSeason={selectedSeason}
        onSelect={(season) => {
          setSelectedSeason(season);
        }}
        competition={selectedCompetition}
        style={{ margin: "1em" }}
        seasons={seasons}
      />
      {selectedSeason && (
        <Button
          style={{ marginBottom: "1em", marginRight: "0px" }}
          green
          onClick={() => {
            createNewGroup();
          }}
        >
          + Create Group
        </Button>
      )}

      <Scroll>
        <Ul>
          {groupsArray?.map((group) => {
            return (
              <Li>
                <A
                  to={
                    "/football/Groups/" + group?._id + "/" + selectedSeason?._id
                  }
                  selected={group?._id === groupId}
                  selectedBG={group?._id === groupId}
                  onClick={() => {
                    setSelectedGroup(group?._id);
                  }}
                >
                  {group?.title}
                </A>
              </Li>
            );
          })}
        </Ul>
      </Scroll>
    </Main>
  );
}

import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import FormationImg from "../../Images/Formation2.png";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #fff;
  margin-top: 100px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
`;

const LastName = styled.div`
  display: flex;
  white-space: nowrap;
  background-color: #e9ecef;
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 300;
  font-size: 13px;
`;

const Row = styled.div`
  position: absolute;
  display: flex;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: center;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const FormationImgDiv = styled.img`
  height: auto;
  width: ${930 / 1.5 + "px"};
  /* position: absolute; */
`;

const FormationRows = styled.div`
  position: relative;
  top: -123px;
`;
const Square = styled.div`
  background-color: ${(props) => (props.selected ? "#db0a41" : "#293b52")};
  color: #fff;
  min-width: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
`;

const PositionLabel = styled.div`
  display: flex;
  white-space: nowrap;
  /* background-color: #e9ecef; */
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 300;
`;
const XSlider = styled.div`
  transform: translate(-50%, -50%);
  left: 0px;
  top: 64px;
  position: absolute;
`;
const YSlider = styled.div`
  transform: translate(-50%, -50%);
  left: 121px;
  top: 287.1px;
  position: absolute;
`;
const Dot = styled.div`
  display: flex;
  background-color: #293b52;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;
const HorizontalLine = styled.div`
  height: 3px;
  width: 967px;
  background-color: #db0a41;
  position: relative;
  top: -14px;
  z-index: -1;
  left: 6px;
`;
const VerticalLine = styled.div`
  height: 567px;
  width: 3px;
  background-color: #db0a41;
  position: relative;
  top: -14px;
  z-index: -1;
  left: 10px;
`;

export default function FormationEdit({ formation, onChange }) {
  const [selectedSquares, setSelectedSquares] = useState([]);
  const [update, setUpdate] = useState(false);

  function updateFormation(formation) {
    onChange(formation);
  }

  let xScale = 7.8 / 1.5;
  let yScale = 3.3 / 1.5;

  function mouseMove(e) {
    if (window.formationDrag && window.formationDrag.down) {
      let diff = e.clientX - window.formationDrag.x;
      let diffY = e.clientY - window.formationDrag.y;

      formation.positions[window.formationDrag.index].x =
        window.formationDrag.start.x + diff / xScale;
      formation.positions[window.formationDrag.index].y =
        window.formationDrag.start.y - diffY / yScale;
      setUpdate(Date.now());
    } else if (window.yDrag && window.yDrag.down) {
      let diffY = e.clientY - window.yDrag.y;

      selectedSquares.forEach((sq) => {
        formation.positions[sq - 1].y = window.yDrag.start.y - diffY / yScale;
      });

      setUpdate(Date.now());
    } else if (window.xDrag && window.xDrag.down) {
      let diff = e.clientX - window.xDrag.x;

      selectedSquares.forEach((sq) => {
        formation.positions[sq - 1].x = window.xDrag.start.x + diff / xScale;
      });

      setUpdate(Date.now());
    }
  }

  function mouseUp(e) {
    console.log("up");
    window.formationDrag = { down: false };
    window.yDrag = { down: false };
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={formation.name}
          title="Name"
          onChange={(e) => {
            let newformation = { ...formation, name: e.target.value };
            updateFormation(newformation);
          }}
        />
        <Input
          value={formation.rows}
          title="Rows"
          onChange={(e) => {
            let newformation = { ...formation, rows: e.target.value };
            updateFormation(newformation);
          }}
        />
        <Main>
          <FormationImgDiv
            src={FormationImg}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
          <FormationRows>
            {selectedSquares && selectedSquares.length > 0 && (
              <XSlider
                style={{
                  left:
                    selectedSquares &&
                    selectedSquares.length > 0 &&
                    formation &&
                    formation.positions[selectedSquares[0] - 1].x * xScale +
                      "px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  let position =
                    formation && formation.positions[selectedSquares[0] - 1];
                  window.xDrag = {
                    x: e.clientX,
                    y: e.clientY,
                    start: { ...position },
                    down: true,
                  };

                  window.addEventListener("mousemove", mouseMove);
                  window.addEventListener("mouseup", mouseUp);
                }}
              >
                <Dot>
                  <BsChevronDown />
                </Dot>
                <VerticalLine></VerticalLine>
              </XSlider>
            )}
            {selectedSquares && selectedSquares.length > 0 && (
              <YSlider
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  top:
                    selectedSquares &&
                    selectedSquares.length > 0 &&
                    formation &&
                    (Math.sign(formation.positions[selectedSquares[0] - 1].y) ==
                    -1
                      ? Math.abs(
                          formation.positions[selectedSquares[0] - 1].y *
                            yScale -
                            -1
                        )
                      : -formation.positions[selectedSquares[0] - 1].y *
                          yScale -
                        -1 +
                        "px"),
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  let position =
                    formation && formation.positions[selectedSquares[0] - 1];
                  window.yDrag = {
                    x: e.clientX,
                    y: e.clientY,
                    start: { ...position },
                    down: true,
                  };

                  window.addEventListener("mousemove", mouseMove);
                  window.addEventListener("mouseup", mouseUp);
                }}
              >
                <Dot>
                  <BsChevronRight />
                </Dot>
                <HorizontalLine></HorizontalLine>
              </YSlider>
            )}

            {formation &&
              formation.positions &&
              new Array(11).fill(1).map((item, index) => {
                let position = formation && formation.positions[index];
                let left = position.x;
                let top =
                  Math.sign(position.y) == -1
                    ? Math.abs(position.y)
                    : -position.y;

                return (
                  <>
                    <Row
                      style={{
                        left: left * xScale + "px",
                        top: top * yScale + "px",
                      }}
                      clickable
                      onClick={(e) => {
                        e.stopPropagation();
                        if (e.ctrlKey) {
                          setSelectedSquares([...selectedSquares, index + 1]);
                        } else {
                          setSelectedSquares([index + 1]);
                        }
                      }}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        if (e.ctrlKey) {
                          setSelectedSquares([...selectedSquares, index + 1]);
                        } else {
                          setSelectedSquares([index + 1]);
                        }
                        window.formationDrag = {
                          x: e.clientX,
                          y: e.clientY,
                          index: index,
                          start: { ...position },
                          down: true,
                        };

                        window.addEventListener("mousemove", mouseMove);
                        window.addEventListener("mouseup", mouseUp);
                      }}
                    >
                      <PositionLabel>
                        x:{parseInt(left * xScale)} y:
                        {parseInt(top * yScale)}
                      </PositionLabel>
                      <Square
                        selected={selectedSquares.indexOf(index + 1) > -1}
                      >
                        {index + 1}
                      </Square>
                      {/* <LastName>{player && player.last_name_upper}</LastName> */}
                    </Row>
                  </>
                );
              })}
          </FormationRows>
        </Main>
      </Grid.Unit>
    </Grid>
  );
}

import { updateLocale } from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { Button } from "../../Theme/Hyper";
import Input from "../UI/Form/Input";
import SelectUI from "../UI/Form/SelectUI";
import Autosuggest from "react-autosuggest";
import "./AutoComplete.css";
import Image from "../UI/Image";
const Main = styled.div`
  width: 100%;
`;
const Options = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #dee2e6;
`;
const Option = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dee2e6;
  margin-right: 1em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Title = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default function CornerBoxEdit({
  box,
  onChange,
  game,
  home_team,
  away_team,
}) {
  const [rows, setRows] = useState(box.number_rows || 5);
  const [columns, setColumns] = useState(box.number_columns || 1);
  const [teams, setTeams] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  useEffect(() => {
    fetch(
      window.location.protocol +
        "//" +
        window.SERVER +
        ":8082/teams?$orderby=name"
    )
      .then((res) => res.json())
      .then((data) => {
        data = data
          .filter((team) => team.opta_ID && team?.variables?.badge)
          .map((team) => {
            return {
              ...team,
              variables: {
                ...team.variables,
                badge: "___MEDIA/FOOTBALL/TEAM_BADGES/" + team.variables?.badge,
              },
            };
          });
        fetch(
          window.location.protocol + "//" + window.SERVER + ":8082/competitions"
        )
          .then((res) => res.json())
          .then((competitions) => {
            competitions.forEach((comp) => {
              data.push({
                name: comp.name,
                variables: { badge: comp?.badge?.replace("IMAGE*", "") },
              });
            });
            setTeams(data);
          });
      });
  }, []);

  box = JSON.parse(JSON.stringify(box));

  let onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();

    let options = teams.filter((team) => {
      return team?.name?.toLowerCase().startsWith(inputValue);
    });
    setSuggestions(options);
  };

  let onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.variables?.badge;

  const renderSuggestion = (suggestion) => {
    return <span>{suggestion.name}</span>;
  };

  return (
    <Main>
      <Row>
        <Title style={{ marginRight: "1em" }}>Type</Title>
        <Buttons>
          <Button
            green={box.type === "game"}
            onClick={() => {
              let newBox = { ...box };
              newBox.type = "game";
              onChange(newBox);
            }}
          >
            Game
          </Button>
          <Button
            green={box.type === "team-" + home_team._id}
            onClick={() => {
              let newBox = { ...box };
              newBox.type = "team-" + home_team._id;
              onChange(newBox);
            }}
          >
            {home_team?.short_name}
          </Button>
          <Button
            green={box.type === "team-" + away_team._id}
            onClick={() => {
              let newBox = { ...box };
              newBox.type = "team-" + away_team._id;
              onChange(newBox);
            }}
          >
            {away_team?.short_name}
          </Button>
          <Button
            green={box.type === "shared"}
            onClick={() => {
              let newBox = { ...box };
              newBox.type = "shared";
              onChange(newBox);
            }}
          >
            Shared
          </Button>
        </Buttons>
      </Row>
      <Row>
        <div style={{ width: "100px" }}>
          <Input
            style={{ width: "100px" }}
            title="#"
            value={box.number}
            onChange={(e) => {
              let newBox = { ...box };
              newBox.number = e.currentTarget.value;
              onChange(newBox);
            }}
          ></Input>
        </div>

        <Input
          title="Stat Name"
          value={box.name}
          onChange={(e) => {
            let newBox = { ...box };
            newBox.name = e.currentTarget.value;
            onChange(newBox);
          }}
        ></Input>
      </Row>
      <Options>
        <Option>
          <Title>Rows</Title>
          <Buttons>
            <Button
              onClick={() => {
                if (rows > 0) {
                  let newBox = { ...box };
                  newBox.number_rows = rows - 1;
                  onChange(newBox);
                  setRows(rows - 1);
                }
              }}
            >
              -
            </Button>
            <span style={{ marginRight: "1em" }}>{rows}</span>
            <Button
              onClick={() => {
                if (rows < 10) {
                  let newBox = { ...box };
                  newBox.number_rows = rows + 1;
                  onChange(newBox);
                  setRows(rows + 1);
                }
              }}
            >
              +
            </Button>
          </Buttons>
        </Option>
        <Option>
          <Title>Columns</Title>
          <Buttons>
            <Button
              onClick={() => {
                if (columns > 0) {
                  let newBox = { ...box };
                  newBox.number_columns = columns - 1;
                  onChange(newBox);
                  setColumns(columns - 1);
                }
              }}
            >
              -
            </Button>
            <span style={{ marginRight: "1em" }}>{columns}</span>
            <Button
              onClick={() => {
                if (columns < 4) {
                  let newBox = { ...box };
                  newBox.number_columns = columns + 1;
                  onChange(newBox);
                  setColumns(columns + 1);
                }
              }}
            >
              +
            </Button>
          </Buttons>
        </Option>
      </Options>
      <Grid>
        <Grid.Unit size={1 / 1}>
          <Grid style={{ width: "100%" }}>
            <Grid.Unit
              size={3 / 13}
              style={{ flexBasis: "28%", maxWidth: "28%" }}
            >
              <Grid style={{ width: "100%" }}>
                <Grid.Unit size={2 / 12}>
                  <Image url={box?.title_badge} style={{ height: "25px" }} />
                  {/* <img
                    style={{ height: "25px" }}
                    src={"/TEAM_BADGES/" + box?.title_badge}
                    onError={(e) => {
                      e.target.src = "/BadgePlaceholder.png";
                    }}
                  /> */}
                </Grid.Unit>

                <Grid.Unit size={10 / 12}>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                      placeholder: "Type a badge name..",
                      value: box?.title_badge || "",
                      onChange: (event, { newValue, method }) => {
                        setValue(newValue);
                        let newBox = { ...box };
                        newBox.title_badge = newValue;
                        onChange(newBox);
                      },
                    }}
                  />

                  {/* <SelectUI
                    value={{
                      value: box.title_badge,
                      label: box.title_badge,
                    }}
                    options={teams?.map((t) => {
                      return { value: t?.variables?.badge, label: t.name };
                    })}
                    placeholder="Select Badge"
                    onChange={(badge) => {
                      let newBox = { ...box };
                      newBox.title_badge = badge;
                      onChange(newBox);
                    }}
                  /> */}
                </Grid.Unit>
              </Grid>
            </Grid.Unit>
            <Grid.Unit
              size={5 / 13}
              style={{
                borderLeft: "2px solid rgb(23, 23, 115)",
                borderRight: "2px solid rgb(23, 23, 115)",
              }}
            >
              <Grid>
                <Grid.Unit size={1 / 1}>
                  <Input
                    placeholder={"First Name"}
                    value={box.first_name}
                    onChange={(e) => {
                      let newBox = { ...box };
                      newBox.first_name = e.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input>
                </Grid.Unit>
                <Grid.Unit size={1 / 1}>
                  <Input
                    multiline={true}
                    placeholder={"Heading/Last Name"}
                    value={box.heading}
                    onChange={(e) => {
                      let newBox = { ...box };
                      newBox.heading = e.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input>
                </Grid.Unit>
              </Grid>
            </Grid.Unit>
          </Grid>
          <Grid>
            <Grid.Unit
              size={3 / 13}
              style={{ flexBasis: "28%", maxWidth: "28%" }}
            ></Grid.Unit>
            <Grid.Unit
              size={5 / 13}
              style={{
                borderLeft: "2px solid rgb(23, 23, 115)",
                borderRight: "2px solid rgb(23, 23, 115)",
              }}
            >
              <Input
                placeholder={"Subtitle"}
                value={box.subtitle}
                onChange={(e) => {
                  let newBox = { ...box };
                  newBox.subtitle = e.currentTarget.value;
                  onChange(newBox);
                }}
              ></Input>
            </Grid.Unit>
            {columns >= 1 && (
              <Grid.Unit size={1 / 13}>
                <Input
                  placeholder={"Col 1"}
                  value={box.column_1}
                  onChange={(e) => {
                    let newBox = { ...box };
                    newBox.column_1 = e.currentTarget.value;
                    onChange(newBox);
                  }}
                ></Input>
              </Grid.Unit>
            )}
            {columns >= 2 && (
              <Grid.Unit size={1 / 13}>
                <Input
                  placeholder={"Col 2"}
                  value={box.column_2}
                  onChange={(e) => {
                    let newBox = { ...box };
                    newBox.column_2 = e.currentTarget.value;
                    onChange(newBox);
                  }}
                ></Input>
              </Grid.Unit>
            )}

            {columns >= 3 && (
              <Grid.Unit size={1 / 13}>
                <Input
                  placeholder={"Col 3"}
                  value={box.column_3}
                  onChange={(e) => {
                    let newBox = { ...box };
                    newBox.column_3 = e.currentTarget.value;
                    onChange(newBox);
                  }}
                ></Input>
              </Grid.Unit>
            )}
            {columns >= 4 && (
              <Grid.Unit size={1 / 13}>
                <Input
                  placeholder={"Col 4"}
                  value={box.column_4}
                  onChange={(e) => {
                    let newBox = { ...box };
                    newBox.column_4 = e.currentTarget.value;
                    onChange(newBox);
                  }}
                ></Input>
              </Grid.Unit>
            )}
          </Grid>
          {new Array(rows).fill(1).map((i, index) => {
            return (
              <Grid
                style={{
                  backgroundColor: box.rows[index].highlighted ? "#151e29" : "",
                }}
              >
                <Grid.Unit
                  size={3 / 13}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexBasis: "28%",
                    maxWidth: "28%",
                  }}
                >
                  <Grid style={{ width: "100%", alignItems: "center" }}>
                    <Grid.Unit size={1 / 12}>
                      <input
                        type="checkbox"
                        checked={box.rows[index].highlighted}
                        onChange={(e) => {
                          let newBox = { ...box };
                          newBox.rows[index].highlighted =
                            e.currentTarget.checked;
                          onChange(newBox);
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={2 / 12}>
                      <Image
                        url={box?.rows[index]?.badge}
                        style={{ height: "25px" }}
                      />

                      {/* <img
                        style={{ height: "25px" }}
                        src={"/TEAM_BADGES/" + box?.rows[index]?.badge}
                        onError={(e) => {
                          e.target.src = "/BadgePlaceholder.png";
                        }}
                      /> */}
                    </Grid.Unit>

                    <Grid.Unit size={9 / 12}>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                          placeholder: "Type a badge name..",
                          value:
                            (box?.rows[index] && box?.rows[index].badge) || "",
                          onChange: (event, { newValue, method }) => {
                            let newBox = { ...box };
                            newBox.rows[index].badge = newValue;
                            onChange(newBox);
                          },
                        }}
                      />

                      {/* <SelectUI
                        value={{
                          value: box.rows[index].badge,
                          label: box.rows[index].badge,
                        }}
                        options={teams?.map((t) => {
                          return { value: t?.variables?.badge, label: t.name };
                        })}
                        placeholder="Select Badge"
                        onChange={(badge) => {
                          let newBox = { ...box };
                          newBox.rows[index].badge = badge;
                          onChange(newBox);
                        }}
                      /> */}
                    </Grid.Unit>
                  </Grid>
                  {/* 
                  <Input
                    placeholder={"Badge"}
                    value={box.rows[index].badge}
                    onChange={(e) => {
                      let newBox = { ...box };
                      newBox.rows[index].badge = e.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input> */}
                </Grid.Unit>

                <Grid.Unit
                  size={1 / 13}
                  style={{
                    borderLeft: "2px solid rgb(23, 23, 115)",
                  }}
                >
                  <Input
                    placeholder={"#"}
                    value={box.rows[index].number}
                    onChange={(e) => {
                      let newBox = { ...box };
                      newBox.rows[index].number = e.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input>
                </Grid.Unit>
                <Grid.Unit size={2 / 13}>
                  <Input
                    placeholder={"Text"}
                    value={box.rows[index].text}
                    onChange={(e) => {
                      let newBox = { ...box };
                      newBox.rows[index].text = e.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input>
                </Grid.Unit>
                <Grid.Unit
                  size={2 / 13}
                  style={{
                    borderRight: "2px solid rgb(23, 23, 115)",
                  }}
                >
                  <Input
                    placeholder={"Small Text"}
                    value={box.rows[index].text_small}
                    onChange={(e) => {
                      let newBox = { ...box };
                      newBox.rows[index].text_small = e.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input>
                </Grid.Unit>
                {columns >= 1 && (
                  <Grid.Unit size={1 / 13}>
                    <Input
                      placeholder={"Col 1"}
                      value={box.rows[index].column_1}
                      onChange={(e) => {
                        let newBox = { ...box };
                        newBox.rows[index].column_1 = e.currentTarget.value;
                        onChange(newBox);
                      }}
                    ></Input>
                  </Grid.Unit>
                )}
                {columns >= 2 && (
                  <Grid.Unit size={1 / 13}>
                    <Input
                      placeholder={"Col 2"}
                      value={box.rows[index].column_2}
                      onChange={(e) => {
                        let newBox = { ...box };
                        newBox.rows[index].column_2 = e.currentTarget.value;
                        onChange(newBox);
                      }}
                    ></Input>
                  </Grid.Unit>
                )}
                {columns >= 3 && (
                  <Grid.Unit size={1 / 13}>
                    <Input
                      placeholder={"Col 3"}
                      value={box.rows[index].column_3}
                      onChange={(e) => {
                        let newBox = { ...box };
                        newBox.rows[index].column_3 = e.currentTarget.value;
                        onChange(newBox);
                      }}
                    ></Input>
                  </Grid.Unit>
                )}
                {columns >= 4 && (
                  <Grid.Unit size={1 / 13}>
                    <Input
                      placeholder={"Col 4"}
                      value={box.rows[index].column_4}
                      onChange={(e) => {
                        let newBox = { ...box };
                        newBox.rows[index].column_4 = e.currentTarget.value;
                        onChange(newBox);
                      }}
                    ></Input>
                  </Grid.Unit>
                )}
              </Grid>
            );
          })}
        </Grid.Unit>
      </Grid>
    </Main>
  );
}

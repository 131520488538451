import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import { Button } from "../../Theme/Hyper";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import CornerBoxEditor from "./CornerBoxEditor";
import LastFive from "./Types/LastFive";
import ManualBox from "./Types/ManualBox";
import NextFive from "./Types/NextFive";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;
const Manual = styled.div`
  width: 100%;
  height: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function CornerBoxes({ fixture }) {
  const [view, setView] = useState(1);
  const [teams, setTeams] = useState(0);
  const [cornerBox, setCornerBox] = useState();
  const [cornerBoxes, setCornerBoxes] = useState();
  const { getTeams, getStatBox, deleteStatBox } = useContext(DataContext);
  const { selectedSeason } = useContext(UIContext);
  useEffect(() => {
    getTeams({}).then((data) => {
      setTeams(data);
    });
    getData();
  }, []);

  function getData() {
    getStatBox({}).then((data) => {
      setCornerBoxes(data);
    });
  }
  return (
    <Main>
      <Tabs>
        {/* <Tab
          left
          selected={view === 0}
          onClick={() => {
            setView(0);
          }}
        >
          OPTA
        </Tab> */}
        <Tab
          selected={view === 1}
          onClick={() => {
            setView(1);
          }}
        >
          This Game
        </Tab>
        <Tab
          selected={view === 2}
          onClick={() => {
            setView(2);
          }}
        >
          {(teams && teams?.find((t) => t._id === fixture?.home_team)?.name) ||
            ""}
        </Tab>
        <Tab
          selected={view === 3}
          onClick={() => {
            setView(3);
          }}
        >
          {(teams && teams?.find((t) => t._id === fixture?.away_team)?.name) ||
            ""}
        </Tab>
        <Tab
          selected={view === 4}
          onClick={() => {
            setView(4);
          }}
        >
          Shared
        </Tab>
      </Tabs>
      <Content>
        {view === 0 && (
          <Grid>
            <Grid.Unit size={1 / 4}>
              <LastFive
                fixture={fixture}
                teams={teams}
                team={
                  teams &&
                  fixture &&
                  teams?.find((t) => t._id === fixture.home_team)
                }
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 4}>
              <NextFive
                fixture={fixture}
                teams={teams}
                team={
                  teams &&
                  fixture &&
                  teams.find((t) => t._id === fixture.home_team)
                }
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 4}>
              <LastFive
                fixture={fixture}
                teams={teams}
                team={
                  teams &&
                  fixture &&
                  teams.find((t) => t._id === fixture.away_team)
                }
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 4}>
              <NextFive
                fixture={fixture}
                teams={teams}
                team={
                  teams &&
                  fixture &&
                  teams.find((t) => t._id === fixture.away_team)
                }
              />
            </Grid.Unit>
          </Grid>
        )}
        {fixture &&
          selectedSeason &&
          (view === 1 || view === 2 || view === 3 || view === 4) && (
            <Panel>
              <Button
                green
                style={{ width: "100px", margin: "1em" }}
                onClick={() => {
                  let number = 1;
                  let cbList = cornerBoxes.filter((item) => {
                    if (view === 1) {
                      return item.game === fixture._id;
                    } else {
                      return !item.game && item.season === selectedSeason._id;
                    }
                  });
                  if (cbList && cbList.length > 0) {
                    number = parseInt(
                      cbList.sort(
                        (a, b) =>
                          parseInt(a.number || 0) - parseInt(b.number || 0)
                      )[cbList.length - 1].number
                    );
                    number++;
                  }
                  let type = "game";
                  switch (view) {
                    case 1:
                      type = "game";
                      break;
                    case 2:
                      type = "team-" + fixture?.home_team;
                      break;
                    case 3:
                      type = "team-" + fixture?.away_team;
                      break;
                    case 4:
                      type = "shared";
                      break;
                  }
                  setCornerBox({
                    type: type,
                    game: fixture._id,
                    season: selectedSeason,
                    number_rows: 5,
                    number_columns: 1,
                    number: number,
                    rows: new Array(10).fill({
                      number: "",
                      text: "",
                      column_1: "",
                      column_2: "",
                      column_3: "",
                      column_4: "",
                    }),
                  });
                }}
              >
                + Create
              </Button>
              <PanelContent>
                <Table>
                  <thead>
                    <tr>
                      <TH style={{ width: "80px" }}>#</TH>
                      <TH>Name</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {cornerBoxes &&
                      cornerBoxes
                        .filter((item) => {
                          if (view === 1) {
                            return (
                              item.type === "game" && item.game === fixture._id
                            );
                          } else if (view === 2) {
                            return item.type === "team-" + fixture?.home_team;
                          } else if (view === 3) {
                            return item.type === "team-" + fixture?.away_team;
                          } else {
                            return item.type === "shared";
                          }
                        })
                        .sort(
                          (a, b) =>
                            parseInt(a.number || 0) - parseInt(b.number || 0)
                        )
                        .map((box) => {
                          return (
                            <tr
                              onClick={() => {
                                setCornerBox(box);
                              }}
                            >
                              <td>{box.number || 0}</td>
                              <td>{box.name}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </PanelContent>
            </Panel>
          )}
        {cornerBox && (
          <CornerBoxEditor
            game={fixture}
            home_team={teams.find((t) => t._id === fixture.home_team)}
            away_team={teams.find((t) => t._id === fixture.away_team)}
            originalCornerBox={cornerBoxes.find((c) => c._id === cornerBox._id)}
            cornerBox={{ ...cornerBox }}
            onCancel={() => setCornerBox()}
            onUpdate={(box) => {
              setCornerBox(box);
            }}
            onSave={() => {
              setCornerBox();
              getData();
            }}
            onDelete={() => {
              deleteStatBox({ id: cornerBox._id }).then(() => {
                getData();
                setCornerBox();
              });
            }}
          ></CornerBoxEditor>
        )}
      </Content>
    </Main>
  );
}

import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 10%;
  color: #606770;
`;
const Game = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin: 0.5em;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#db0a41" : "white")};
  color: ${(props) => (props.selected ? "white" : "")};
  :hover {
    background-color: ${(props) => (props.selected ? "#db0a41" : "#f7f7f7")};
    color: ${(props) => (props.selected ? "white" : "")};
  }
`;
const Team = styled.div`
  display: flex;
`;
export default function Games({ fixtures, teams, onSelect, selectedGame }) {
  return (
    <Main>
      {fixtures.map((fixture) => {
        let homeTeam = teams.find((t) => t.uuid === fixture.home_team);
        let awayTeam = teams.find((t) => t.uuid === fixture.away_team);
        return (
          <Game
            selected={selectedGame && selectedGame.uuid === fixture.uuid}
            onClick={() => {
              onSelect(fixture);
            }}
          >
            <Team>{homeTeam.name}</Team>v<Team>{awayTeam.name}</Team>
          </Game>
        );
      })}
    </Main>
  );
}

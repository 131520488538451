import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  display: flex;
  align-items: center;
  background-color: #dee2e6;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  top: 60px;
  z-index: 1000;
  width: 100%;
`;

const A = styled(Link)`
  text-decoration: none;
  font-size: 0.8em;
  color: ${(props) => (props.active ? "#495057" : "#868e96")};
  :not(:first-child) {
    :before {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: #868e96;
      content: "/";
    }
  }
`;

const FakeA = styled.span`
  text-decoration: none;
  font-size: 0.8em;
  color: ${(props) => (props.active ? "#495057" : "#868e96")};
  :not(:first-child) {
    :before {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: #868e96;
      content: "/";
    }
  }
`;

const routes = [
  { path: "/football/fixtures/:fixtureId", breadcrumb: FixtureCrumb },
  { path: "/football/teams/:teamId", breadcrumb: TeamCrumb },
  { path: "/football/teams/:teamId/:seasonId", breadcrumb: SeasonCrumb },
];

export default function Breadcrumb() {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Main>
      <nav>
        {breadcrumbs.map(({ match, breadcrumb }) => {
          if (breadcrumb.key !== "/" && breadcrumb.key !== "/football") {
            return (
              <A key={breadcrumb.key} to={match.url}>
                {breadcrumb}
              </A>
            );
          } else {
            return <FakeA key={breadcrumb.key}>{breadcrumb}</FakeA>;
          }
        })}
      </nav>

      {/* <nav>
        <A to="">Dashboard</A>
        <A to="">Rugby</A>
        <A to="" active>
          Fixtures
        </A>
      </nav> */}
    </Main>
  );
}

function FixtureCrumb({ match }) {
  const { getFixture } = useContext(DataContext);
  const [name, setName] = useState();

  useEffect(() => {
    getFixture({ fixtureId: match.params.fixtureId }).then((data) => {
      setName(data?.description);
    });
  }, []);
  return name || "";
}

function TeamCrumb({ match }) {
  const { getTeams } = useContext(DataContext);
  const [name, setName] = useState();

  useEffect(() => {
    getTeams().then((data) => {
      setName(data?.find((t) => t._id === match.params.teamId)?.name);
    });
  }, []);
  return name || "";
}

function SeasonCrumb({ match }) {
  const { getSeason } = useContext(DataContext);
  const [name, setName] = useState();

  useEffect(() => {
    getSeason().then((data) => {
      setName(data?.find((t) => t._id === match.params.seasonId)?.name);
    });
  }, []);
  return name || "";
}

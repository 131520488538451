import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import FormationEdit from "../Formation/FormationEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function FormationEditor({
  formation,
  onSave,
  onCancel,
  selectFormation,
}) {
  const { updateFormation, deleteFormation } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={formation}
      onCancel={() => {
        selectFormation();
        onCancel();
      }}
    >
      {formation && (
        <Panel
          style={{ height: "750px", marginTop: "-15%", overflow: "hidden" }}
        >
          <PanelTitle title={"Formation Edit"}>
            <div>{formation._id}</div>
          </PanelTitle>
          <PanelContent>
            <FormationEdit
              formation={formation}
              onChange={(s) => {
                selectFormation(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={true}
            onDelete={() => {
              deleteFormation({ data: formation }).then(() => {
                selectFormation();
                onSave();
              });
            }}
            onSave={() => {
              updateFormation({ data: formation }).then(() => {
                selectFormation();
                onSave();
              });
            }}
            onCancel={() => {
              selectFormation();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}

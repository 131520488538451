import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Grid from "styled-components-grid";
const Container = styled.div`
  display: flex;
  width: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const StandingsDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ecef;
`;

const Row = styled.div`
  display: flex;
  padding: 7px;
  border-bottom: ${(props) => (props.border ? "1px solid #293b52" : "")};
  border-top: ${(props) => (props.border_top ? "1px solid #293b52" : "")};
  :hover {
    background-color: #dadcde;
  }
`;

const Position = styled.div`
  display: flex;
  min-width: 40px;
  text-align: center;
  justify-content: center;
`;
const TeamName = styled.div`
  display: flex;
  min-width: 150px;
  margin-left: 0.5em;
`;
const Column = styled.div`
  display: flex;
  min-width: 50px;
  text-align: center;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  padding: 7px;
  background-color: #49617f;
  color: #fff;
`;
export default function Standings({ fixture }) {
  const [standings, setStandings] = useState();
  const [teams, setTeams] = useState();
  const { getStandings, getTeams } = useContext(DataContext);
  const { selectedSeason } = useContext(UIContext);

  useEffect(() => {
    if (selectedSeason) {
      getTeams({}).then((data) => {
        setTeams(data);
      });
      getStandings({ season: selectedSeason._id }).then((data) => {
        setStandings(data);
      });
    }
  }, [selectedSeason]);

  return (
    <Container>
      <Grid style={{ width: "100%" }}>
        <Grid.Unit size={10 / 10}>
          <Panel>
            <PanelTitle title={"Standings"} />
            <PanelContent>
              <StandingsDiv>
                <Header>
                  <Position>#</Position>
                  <TeamName>Team</TeamName>
                  <Column>Pld</Column>
                  <Column>W</Column>
                  <Column>D</Column>
                  <Column>L</Column>
                  <Column>F</Column>
                  <Column>A</Column>
                  <Column>GD</Column>
                  <Column>Pts</Column>
                </Header>
                {teams &&
                  standings &&
                  standings.teams &&
                  standings.teams.map((row) => {
                    let team = teams.find((t) => t._id === row.team);
                    let border = false;
                    let border_top = false;

                    standings.qualification.Type.forEach((type) => {
                      if (type.Team._) {
                        if (type.Team.team_id == team.opta_legacy_ID) {
                          border = true;
                        }
                      } else {
                        if (
                          type.qualify == "Relegation" &&
                          type.Team[0].team_id == team.opta_legacy_ID
                        ) {
                          border_top = true;
                        }
                        if (
                          type.Team.slice(-1)[0].team_id == team.opta_legacy_ID
                        ) {
                          border = true;
                        }
                      }
                    });
                    return (
                      <Row border={border} border_top={border_top}>
                        <Position>{row.position}</Position>
                        <TeamName>{team.short_name}</TeamName>
                        <Column>{row.played}</Column>
                        <Column>{row.won}</Column>
                        <Column>{row.drawn}</Column>
                        <Column>{row.lost}</Column>
                        <Column>{row.for}</Column>
                        <Column>{row.against}</Column>
                        <Column>{row.for - row.against}</Column>
                        <Column>{row.points}</Column>
                      </Row>
                    );
                  })}
              </StandingsDiv>
            </PanelContent>
          </Panel>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

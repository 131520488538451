import React, { useContext, useState, useEffect } from "react";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
import Input from "../../UI/Form/Input";
import { SocketContext } from "../../../contexts/SocketContext";
import { HiSwitchHorizontal } from "react-icons/hi";
import { UIContext } from "../../../contexts/UIContext";

const Row = styled.div`
  display: flex;
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
  margin-right: auto;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const SwitchButton = styled(Button)`
  height: 40px;
  font-size: 1em;
  margin-right: 0px;
  width: 33%;
`;
export default function ActionAreas({ fixture }) {
  const { game, sendData } = useContext(SocketContext);
  const { selectedCompetition } = useContext(UIContext);

  const [view, setView] = useState(0);

  if (!fixture) {
    return <></>;
  }

  return (
    <Panel>
      <PanelTitle title="Action Areas"></PanelTitle>
      <PanelContent
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <opta-widget
          widget="action_areas"
          competition={"8"}
          season="2021"
          match={"2210271"}
          template="normal"
          live="true"
          animation="false"
          show_match_header="false"
          show_crests="false"
          show_pitch_crests="true"
          show_competition_name="false"
          competition_naming="full"
          show_date="false"
          date_format="dddd D MMMM YYYY"
          team_naming="full"
          show_timecontrols="true"
          title="Action Areas"
          show_title="false"
          show_logo="false"
          show_live="false"
          breakpoints="0"
          sport="football"
        ></opta-widget>

        <Tabs>
          <Tab
            selected={view === 0}
            onClick={() => {
              setView(0);
            }}
          >
            1st Half
          </Tab>
          <Tab
            selected={view === 1}
            onClick={() => {
              setView(1);
            }}
          >
            2nd Half
          </Tab>
        </Tabs>
        {view === 0 && (
          <HalfData
            game={game}
            firstHalf={true}
            sendData={sendData}
            fixture={fixture}
          />
        )}
        {view === 1 && (
          <HalfData
            game={game}
            firstHalf={false}
            sendData={sendData}
            fixture={fixture}
          />
        )}
      </PanelContent>
    </Panel>
  );
}

function HalfData({ game, firstHalf, sendData, fixture }) {
  const [left, setLeft] = useState();
  const [centre, setCentre] = useState();
  const [right, setRight] = useState();
  const [leftTeam, setLeftTeam] = useState();
  const [leftPossession, setLeftPossession] = useState();
  const [rightPossession, setRightPossession] = useState();
  const [possessionTime, setPossessionTime] = useState();

  // useEffect(() => {
  //   window.Opta.start();
  // }, []);
  function updateData({ variable, data }) {
    if (firstHalf) {
      variable = "hyper_stats.game.action_areas.first." + variable;
    } else {
      variable = "hyper_stats.game.action_areas.second." + variable;
    }
    sendData({
      type: "update_game",
      _id: fixture._id,
      variable: variable,
      data: data,
    });
  }

  function updatePossessionData({ variable, data }) {
    if (firstHalf) {
      variable = "hyper_stats.game.possession.first." + variable;
    } else {
      variable = "hyper_stats.game.possession.second." + variable;
    }
    sendData({
      type: "update_game",
      _id: fixture._id,
      variable: variable,
      data: data,
    });
  }

  useEffect(() => {
    if (game) {
      if (firstHalf) {
        if (possessionTime == null) {
          setPossessionTime(game?.hyper_stats?.game?.possession?.first?.time);
        }
        if (leftPossession == null) {
          setLeftPossession(game?.hyper_stats?.game?.possession?.first?.home);
        }
        if (rightPossession == null) {
          setRightPossession(game?.hyper_stats?.game?.possession?.first?.away);
        }
        if (leftTeam == null) {
          setLeftTeam(
            game?.hyper_stats?.game?.action_areas?.first?.left_team || "home"
          );
        }
        if (left == null) {
          setLeft(game?.hyper_stats?.game?.action_areas?.first?.left);
        }
        if (centre == null) {
          setCentre(game?.hyper_stats?.game?.action_areas?.first?.centre);
        }
        if (right == null) {
          setRight(game?.hyper_stats?.game?.action_areas?.first?.right);
        }
      } else {
        if (possessionTime == null) {
          setPossessionTime(game?.hyper_stats?.game?.possession?.second?.time);
        }
        if (leftPossession == null) {
          setLeftPossession(game?.hyper_stats?.game?.possession?.second?.home);
        }
        if (rightPossession == null) {
          setRightPossession(game?.hyper_stats?.game?.possession?.second?.away);
        }
        if (leftTeam == null) {
          setLeftTeam(
            game?.hyper_stats?.game?.action_areas?.second?.left_team || "home"
          );
        }
        if (left == null) {
          setLeft(game?.hyper_stats?.game?.action_areas?.second?.left);
        }
        if (centre == null) {
          setCentre(game?.hyper_stats?.game?.action_areas?.second?.centre);
        }
        if (right == null) {
          setRight(game?.hyper_stats?.game?.action_areas?.second?.right);
        }
      }
    }
  });

  return (
    <Half>
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <img
          style={{ width: "33%", objectFit: "contain" }}
          height={"80px"}
          src={
            game &&
            leftTeam &&
            "/TEAM_BADGES/" + game[leftTeam + "_team"]?.variables?.badge
          }
          onError={(e) => {
            e.target.src = "/BadgePlaceholder.png";
          }}
        />
        <SwitchButton
          onClick={() => {
            let team = leftTeam === "home" ? "away" : "home";
            setLeftTeam(team);
            updateData({
              variable: "left_team",
              data: team,
            });
            updateData({
              variable: "right_team",
              data: team === "home" ? "away" : "home",
            });
          }}
        >
          <HiSwitchHorizontal />
        </SwitchButton>
        <img
          style={{ width: "33%", objectFit: "contain" }}
          height={"80px"}
          src={
            game &&
            leftTeam &&
            "/TEAM_BADGES/" +
              game[(leftTeam === "home" ? "away" : "home") + "_team"]?.variables
                ?.badge
          }
          onError={(e) => {
            e.target.src = "/BadgePlaceholder.png";
          }}
        />
      </Row>
      <Row>
        <Input
          title={"Left"}
          style={{ width: "33%" }}
          value={left}
          onChange={(e) => {
            setLeft(e.currentTarget.value);
            updateData({ variable: "left", data: e.currentTarget.value });
          }}
        />
        <Input
          title={"Centre"}
          style={{ width: "33%" }}
          value={centre}
          onChange={(e) => {
            setCentre(e.currentTarget.value);
            updateData({ variable: "centre", data: e.currentTarget.value });
          }}
        />
        <Input
          title={"Right"}
          style={{ width: "33%" }}
          value={right}
          onChange={(e) => {
            setRight(e.currentTarget.value);
            updateData({ variable: "right", data: e.currentTarget.value });
          }}
        />
      </Row>
      <Row>
        Possession Last{" "}
        <Button
          green={possessionTime === 5}
          style={{ padding: "2px", minWidth: "40px", marginLeft: "auto" }}
          onClick={() => {
            setPossessionTime(5);
            updatePossessionData({
              variable: "time",
              data: 5,
            });
          }}
        >
          5m
        </Button>
        <Button
          green={possessionTime === 10}
          style={{ padding: "2px", minWidth: "40px" }}
          onClick={() => {
            setPossessionTime(10);
            updatePossessionData({
              variable: "time",
              data: 10,
            });
          }}
        >
          10m
        </Button>
        <Button
          green={possessionTime === 15}
          style={{ padding: "2px", minWidth: "40px" }}
          onClick={() => {
            setPossessionTime(15);
            updatePossessionData({
              variable: "time",
              data: 15,
            });
          }}
        >
          15m
        </Button>
      </Row>
      <Row>
        <Half>
          <Input
            title={game?.home_team?.code}
            style={{ width: "33%" }}
            value={leftPossession}
            onChange={(e) => {
              setLeftPossession(e.currentTarget.value);
              updatePossessionData({
                variable: "home",
                data: e.currentTarget.value,
              });
            }}
          />
        </Half>
        <Half>
          <Input
            title={game?.away_team?.code}
            style={{ width: "33%" }}
            value={rightPossession}
            onChange={(e) => {
              setRightPossession(e.currentTarget.value);
              updatePossessionData({
                variable: "away",
                data: e.currentTarget.value,
              });
            }}
          />
        </Half>
      </Row>
    </Half>
  );
}

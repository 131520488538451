import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
`;
export default function StageContent({ children }) {
  return <Main>{children}</Main>;
}

import React, { useState } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import Input from "../UI/Form/Input";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
const KitColour = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgb(43 43 43 / 38%);
  margin: 0.5em;
  cursor: pointer;
  min-height: 15px;
  min-width: 25px;
`;

const KitContainer = styled.div`
  display: flex;
  border-bottom: ${(props) => (props.selected ? "2px solid red" : "")};
  margin-bottom: 0.5em;
`;
const KitList = styled.div`
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin: 1em;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  padding: 0.5em;
  width: 100%;
  font-size: 0.8em;
  font-weight: 100;
  align-items: center;
  :nth-of-type(even) {
    background-color: #0000000d;
  }
`;
const Label = styled.div`
  display: flex;
  min-width: 150px;
`;

export default function Kits({ team }) {
  const [selectedKitName, setSelectedKitName] = useState(
    team.kits && team.kits.length > 0 && team.kits[0].name
  );
  const [pickerLocation, setPickerLocation] = useState();
  const [showPicker, setShowPicker] = useState();
  const [colour, setColour] = useState();
  const [variable, setVariable] = useState();

  console.log(team);

  let selectedKit =
    team.kits &&
    team.kits.find((kit) => {
      return kit.name === selectedKitName;
    });
  console.log(selectedKit);
  function mouseDown() {
    console.log("mouse down");
    setShowPicker(false);
    window.removeEventListener("mousedown", mouseDown);
  }
  return (
    <Main>
      <KitList>
        {team.kits &&
          team.kits.map((kit) => {
            return (
              <KitContainer
                selected={kit.name === selectedKitName && selectedKitName}
              >
                <KitColour
                  onClick={() => {
                    setSelectedKitName(kit.name);
                  }}
                  style={{
                    backgroundColor: kit.colour,
                    color: kit.text_colour,
                  }}
                >
                  {team.code}
                </KitColour>
              </KitContainer>
            );
          })}
        {/* <KitColour>+</KitColour> */}
      </KitList>
      <Content>
        <Row>
          <Label>Name</Label>
          <Input
            value={selectedKitName && selectedKit.name}
            onChange={(e) => {
              team.kits.find(
                (k) => k.name === selectedKitName && selectedKit.name
              ).name = e.target.value;
              setSelectedKitName(e.target.value);
            }}
          ></Input>
        </Row>
        <Row>
          <Label>Background Colour</Label>
          <KitColour
            onClick={(e) => {
              setVariable("colour");
              setPickerLocation({
                x:
                  e.currentTarget.offsetLeft + e.currentTarget.clientWidth + 10,
                y: e.currentTarget.offsetTop,
              });
              setShowPicker(true);
              window.addEventListener("mousedown", mouseDown);
            }}
            style={{ backgroundColor: selectedKitName && selectedKit.colour }}
          ></KitColour>
        </Row>
        <Row>
          <Label>Text Colour</Label>
          <KitColour
            style={{
              backgroundColor: selectedKitName && selectedKit.text_colour,
            }}
            onClick={(e) => {
              setVariable("text_colour");
              setPickerLocation({
                x:
                  e.currentTarget.offsetLeft + e.currentTarget.clientWidth + 10,
                y: e.currentTarget.offsetTop,
              });
              setShowPicker(true);
              window.addEventListener("mousedown", mouseDown);
            }}
          ></KitColour>
        </Row>
      </Content>
      {showPicker && (
        <div
          style={{
            position: "absolute",
            left: pickerLocation.x + "px",
            top: pickerLocation.y + "px",
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        >
          <SketchPicker
            color={selectedKit[variable]}
            onChange={(color, ev) => {
              team.kits.find(
                (k) => k.name === selectedKitName && selectedKit.name
              )[variable] = color.hex;
              setColour(color);
            }}
          />
        </div>
      )}
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import SelectUI from "../UI/Form/SelectUI";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
`;
export default function CompetitionList({
  onSelect,
  style,
  selectedCompetition,
}) {
  const { getCompetition } = useContext(DataContext);
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    getCompetition().then((data) => {
      setCompetitions([...data]);
    });
  }, []);

  return (
    <Main style={style}>
      <SelectUI
        value={
          selectedCompetition
            ? {
                value: selectedCompetition,
                label:
                  (selectedCompetition?.country
                    ? selectedCompetition?.country + " | "
                    : "") + selectedCompetition?.name,
              }
            : ""
        }
        options={competitions
          .sort((a, b) => {
            if (a.country < b.country) {
              return -1;
            }
            if (a.country > b.country) {
              return 1;
            }
            return (a.order || 999) - (b.order || 999);
          })
          .map((c) => {
            return {
              value: c,
              label: (c?.country ? c?.country + " | " : "") + c?.name,
            };
          })}
        placeholder="Select Competition"
        onChange={(comp) => {
          onSelect(comp);
        }}
      />
    </Main>
  );
}

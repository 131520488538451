import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Json = styled.div`
  white-space: pre;
`;

export default function Config() {
  const { getServerConfig } = useContext(DataContext);
  const [config, setConfig] = useState({});
  useEffect(() => {
    getServerConfig().then((data) => {
      setConfig(data);
    });
  }, []);
  return (
    <Main>
      <Grid style={{ width: "100%" }}>
        <Grid.Unit size={1 / 1} style={{ marginTop: ".5em" }}>
          <Panel>
            <PanelContent>
              <Json> {JSON.stringify(config, null, "\t")}</Json>
            </PanelContent>
          </Panel>
        </Grid.Unit>
      </Grid>
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import moment from "moment";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Button = styled.a`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) =>
    props.green ? "#14ec8e" : props.red ? "#db0a41" : "#dee2e6"};
  color: ${(props) => (props.green || props.red ? "#fff" : "")};
  font-size: 0.8em;
  text-decoration: none;
  :active {
    color: ${(props) => (props.green || props.red ? "#fff" : "")};
  }
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.green ? "#0ed47e" : props.red ? "#b10835" : "#dadcde"};
  }
`;
const Content = styled.div`
  display: flex;
  margin: 1em;
`;
export default function Database() {
  const { downloadDatabase } = useContext(DataContext);
  return (
    <Main>
      <Content>
        <Grid style={{ width: "100%" }}>
          <Button
            href={window.DATA_SERVER + "/hyper/v1/database/backup"}
            download
          >
            DOWNLOAD
          </Button>
        </Grid>
      </Content>
    </Main>
  );
}

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Main = styled.img``;

export default function Image({ url, style, placeholder }) {
  const imgRef = useRef();

  return (
    <Main
      style={style}
      ref={imgRef}
      onError={(e) => {
        e.target.src = placeholder || "/BadgePlaceholder.png";
      }}
    />
  );
}

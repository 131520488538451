import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import CountryEdit from "../Countries/CountryEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function CountryEditor({
  country,
  onSave,
  onCancel,
  selectCountry,
}) {
  const { updateCountry } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={country}
      onCancel={() => {
        selectCountry();
        onCancel();
      }}
    >
      {country && (
        <Panel style={{ height: "300px", marginTop: "-25%" }}>
          <PanelTitle title={"Country Edit"}>
            <div>{country._id}</div>
          </PanelTitle>
          <PanelContent>
            <CountryEdit
              country={country}
              onChange={(s) => {
                selectCountry(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={false}
            onSave={() => {
              updateCountry({ data: country }).then(() => {
                selectCountry();
                onSave();
              });
            }}
            onCancel={() => {
              selectCountry();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}

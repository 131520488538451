import React from "react";
import styled from "styled-components";

const Main = styled.div`
  position: relative;
  display: inline-block;

  :hover .tip {
    visibility: visible;
  }
`;

const Tip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;

  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
export default function Tooltip({ children, text, colour }) {
  return (
    <Main
      style={{
        borderBottom: colour ? "1px dotted " + colour : "1px dotted black",
      }}
    >
      {children}
      <Tip className="tip">{text}</Tip>
    </Main>
  );
}

import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import StadiumEdit from "../Stadiums/StadiumEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function StadiumEditor({
  stadium,
  onSave,
  onCancel,
  selectStadium,
}) {
  const { updateStadium, deleteStadium } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={stadium}
      onCancel={() => {
        selectStadium();
        onCancel();
      }}
    >
      {stadium && (
        <Panel style={{ height: "300px", marginTop: "-25%" }}>
          <PanelTitle title={"Stadium Edit"}>
            <div>{stadium._id}</div>
          </PanelTitle>
          <PanelContent>
            <StadiumEdit
              stadium={stadium}
              onChange={(s) => {
                selectStadium(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={false}
            onDelete={() => {
              deleteStadium({ data: stadium }).then(() => {
                onSave();
              });
            }}
            onSave={() => {
              updateStadium({ data: stadium }).then(() => {
                selectStadium();
                onSave();
              });
            }}
            onCancel={() => {
              selectStadium();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}

import React, { useContext, useState } from "react";
import styled from "styled-components";
import CalendarUI from "../UI/Calendar/CalendarUI";
import moment from "moment";
const Main = styled.div`
  width: 275px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;

  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  position: fixed;
  top: 112px;
  z-index: 1000;
  flex-direction: column;
`;
export default function Calendar({
  onChangeDate,
  highlights,
  onActiveStartDateChange,
}) {
  const [date, setDate] = useState(moment());
  return (
    <Main>
      <CalendarUI
        onActiveStartDateChange={onActiveStartDateChange}
        highlights={highlights}
        onChangeDate={(date) => {
          setDate(date);
          onChangeDate(date);
        }}
        date={date}
      />
    </Main>
  );
}

import React, { useContext } from "react";
import styled from "styled-components";
import SettingsList from "../Settings/SettingsList.js";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  return (
    <Main>
      <SettingsList />
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import CompetitionEditor from "../Modals/CompetitionEditor";
import { Button } from "../../Theme/Hyper";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Swatch = styled.div`
  height: 17px;
  width: 40px;
  background-color: ${(props) => props.colour};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export default function Competitions() {
  const { getCompetition, deleteCompetition } = useContext(DataContext);
  const [competitions, setCompetitions] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [showProcessedOnly, setShowProcessedOnly] = useState(false);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getCompetition().then((data) => {
      setCompetitions(
        [...data].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }

  function selectAll({ competitions }) {
    setSelectedCompetitions(competitions);
  }
  return (
    <>
      <Button
        green
        style={{ margin: "1em", width: "200px" }}
        onClick={() => {
          setSelectedCompetition({});
        }}
      >
        + Create Competition
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Competition List"} />
        <PanelContent>
          <Column>
            <div>
              <Button
                green={showProcessedOnly}
                style={{ display: "inline-block" }}
                onClick={() => {
                  setShowProcessedOnly(!showProcessedOnly);
                }}
              >
                SHOW ONLY PROCESSED DATA
              </Button>
            </div>
            <Table>
              <thead>
                <tr>
                  <TH style={{ width: "50px" }}>
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (e.currentTarget.checked) {
                          selectAll({ competitions });
                        } else {
                          selectAll({ competitions: [] });
                        }
                      }}
                    />
                  </TH>
                  <TH></TH>
                  <TH></TH>
                  <TH></TH>
                  <TH></TH>
                  <TH></TH>
                  <TH></TH>
                  <TH></TH>
                  <TH>
                    {selectedCompetitions?.length > 0 && (
                      <Button
                        red
                        onClick={async () => {
                          for (
                            let i = 0;
                            i < selectedCompetitions?.length;
                            i++
                          ) {
                            await deleteCompetition({
                              data: selectedCompetitions[i],
                            });
                          }
                          setSelectedCompetitions([]);
                          getData();
                        }}
                      >
                        Delete selected
                      </Button>
                    )}
                  </TH>
                </tr>
                <tr>
                  <TH style={{ width: "50px" }}></TH>
                  <TH>ID</TH>
                  <TH>OPTA ID</TH>
                  <TH>Country</TH>
                  <TH>Colour</TH>
                  <TH>Process OPTA Data</TH>
                  <TH>Name</TH>
                  <TH>Badge</TH>
                  <TH>Order</TH>
                </tr>
              </thead>
              <tbody>
                {competitions &&
                  competitions
                    .sort((a, b) => {
                      if (a.country < b.country) {
                        return -1;
                      }
                      if (a.country > b.country) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.filter((comp) => {
                      if (showProcessedOnly) {
                        return comp.process_data;
                      }
                      return true;
                    })
                    .map((competition) => {
                      return (
                        <tr
                          onClick={() => {
                            setSelectedCompetition(competition);
                          }}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                selectedCompetitions?.findIndex(
                                  (c) => c?._id === competition?._id
                                ) > -1
                              }
                              onClick={(e) => {
                                e.stopPropagation();

                                let new_comps = JSON.parse(
                                  JSON.stringify(selectedCompetitions)
                                );
                                new_comps = new_comps?.filter((c) => {
                                  return (
                                    c?._id?.toString() !==
                                    competition?._id?.toString()
                                  );
                                });
                                if (e.currentTarget.checked) {
                                  debugger;
                                  new_comps.push(competition);
                                }
                                setSelectedCompetitions(new_comps);
                              }}
                            />
                          </td>
                          <td>{competition._id}</td>
                          <td>{competition.opta_ID}</td>
                          <td>{competition.country}</td>
                          <td>
                            <Swatch colour={competition.colour} />
                          </td>
                          <td>{competition.process_data ? "Yes" : "No"}</td>
                          <td>{competition.name}</td>
                          <td>{competition.badge}</td>
                          <td>{competition.order}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Column>
        </PanelContent>
      </Panel>
      {selectedCompetition && (
        <CompetitionEditor
          competition={selectedCompetition}
          selectCompetition={(s) => {
            setSelectedCompetition(s);
          }}
          onCancel={() => {
            setSelectedCompetition();
          }}
          onSave={() => {
            setSelectedCompetition();
            getData();
          }}
        />
      )}
    </>
  );
}

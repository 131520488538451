import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";
import GroupList from "../Group/GroupList";
import Group from "../Group/Group";

const Main = styled.div`
  display: flex;
  background-color: #ececec;
`;
const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
`;
export default function Groups() {
  const [groups, setGroups] = useState();
  const { getGroup } = useContext(DataContext);
  let match = useRouteMatch();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    debugger;
    getGroup().then((data) => {
      setGroups(data);
    });
  }

  return (
    <Main>
      <>
        <Switch>
          <Route path={`${match.path}/:groupId/:seasonId`}>
            <GroupList groups={groups} onUpdate={getData} />
            <Content>
              <Group groups={groups} onUpdate={getData} />
            </Content>
          </Route>
          <Route path={`${match.path}`}>
            <GroupList groups={groups} onUpdate={getData} />
          </Route>
        </Switch>
      </>
    </Main>
  );
}

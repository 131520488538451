import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import { AiOutlineCloudUpload } from "react-icons/ai";
import SelectUI from "../UI/Form/SelectUI";
import { SketchPicker } from "react-color";
import { CgColorPicker } from "react-icons/cg";
const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
export default function CompetitionEdit({ competition, onChange }) {
  const [selectedCompetitionType, setSelectedCompetitionType] = useState();
  const [selectedCompetitionProcessData, setSelectedCompetitionProcessData] =
    useState();

  function updateCompetition(competition) {
    onChange(competition);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={competition.name}
          title="Name"
          onChange={(e) => {
            let newCompetition = { ...competition, name: e.target.value };
            updateCompetition(newCompetition);
          }}
        />
        <Input
          value={competition.country}
          title="Country"
          onChange={(e) => {
            let newCompetition = { ...competition, country: e.target.value };
            updateCompetition(newCompetition);
          }}
        />
        <Input
          value={competition.order}
          title="Order"
          onChange={(e) => {
            let newCompetition = { ...competition, order: e.target.value };
            updateCompetition(newCompetition);
          }}
        />
        <ColourPicker
          title="Colour"
          value={competition.colour || ""}
          onChange={(value) => {
            let newCompetition = { ...competition, colour: value };
            updateCompetition(newCompetition);
          }}
        />
        <FileUpload
          id="badge"
          label="Badge"
          value={competition?.badge || ""}
          onChange={(filename) => {
            let newCompetition = { ...competition, badge: filename };
            updateCompetition(newCompetition);
          }}
        />
        <SelectUI
          title="Competition type"
          value={
            selectedCompetitionType
              ? {
                  value: selectedCompetitionType,
                  label: selectedCompetitionType,
                }
              : {
                  value: competition?.competition_type,
                  label: competition?.competition_type,
                }
          }
          options={[
            { value: "regular", label: "regular" },
            { value: "beach", label: "beach" },
          ]}
          placeholder="Select competition type"
          onChange={(type) => {
            let newCompetition = { ...competition, competition_type: type };
            setSelectedCompetitionType(type);
            updateCompetition(newCompetition);
          }}
        />
        <SelectUI
          title="Process OPTA Data"
          value={
            selectedCompetitionProcessData
              ? {
                  value: selectedCompetitionProcessData,
                  label: selectedCompetitionProcessData ? "Yes" : "No",
                }
              : {
                  value: competition?.process_data,
                  label: competition?.process_data ? "Yes" : "No",
                }
          }
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          placeholder="Process OPTA data for competition"
          onChange={(type) => {
            debugger;
            let newCompetition = { ...competition, process_data: type };
            setSelectedCompetitionProcessData(type);
            updateCompetition(newCompetition);
          }}
        />
      </Grid.Unit>
    </Grid>
  );
}

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
const Popup = styled.div`
  position: absolute;
  z-index: 100;
`;
function ColourPicker({ value, onChange, title }) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={title}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => setOpen(!open)}
        >
          {!value && <CgColorPicker />}
        </Swatch>

        {open && (
          <Popup>
            <Cover onClick={handleClose} />
            <SketchPicker
              color={value || "#fff"}
              onChange={(colour) => {
                onChange(colour.hex);
              }}
            />
          </Popup>
        )}
      </div>
    </ColourContainer>
  );
}

function FileUpload({ value, onChange, label, id }) {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("user-id");
  const project_serevr_url =
    window.ENV?.REACT_APP_PROJECT_SERVER ||
    process.env.REACT_APP_PROJECT_SERVER;

  function handleClose() {
    setOpen(false);
  }

  function upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
    });

    fetch(`${project_serevr_url}/api/assets/images/${userId}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      onChange(files[0].name.replace(/ /g, "_"));
    });
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={label}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <input
        id={id}
        type="file"
        name="file"
        accept=".png, .jpg"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => document.getElementById(id).click()}
        >
          {!value && <AiOutlineCloudUpload />}
          {value && (
            <img
              style={{ objectFit: "contain", width: "50px", height: "30px" }}
              src={
                project_serevr_url +
                "/api/assets/images/" +
                userId +
                "/" +
                value
              }
              alt="Competition Badge"
            />
          )}
        </Swatch>
      </div>
    </ColourContainer>
  );
}

import React, { useEffect, useState } from "react";
import _ from "lodash";
const SocketContext = React.createContext([{}, () => {}]);

const SocketProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [game, setGame] = useState(null);
  const [logs, setLogs] = useState([]);
  const [monitorGames, setMonitorGames] = useState([]);
  const [subscribedGameId, setSubscribedGameId] = useState();
  const [server, setServer] = useState(null);
  const [monitorMachines, setMonitorMachines] = useState([]);

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Connecting to football server");
    return;
    // setWS(
    //   new WebSocket(
    //     (window.location.protocol.indexOf("https") > -1 ? "wss" : "ws") +
    //       "://" +
    //       window.SERVER +
    //       ":8083"
    //   )
    // );
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;
      ws.onopen = () => {
        setWSStatus(1);
        console.log("Football server on open");
        if (subscribedGameId) {
          subscribeToGame(subscribedGameId);
        }
        subscribeToMonitor();
        clearInterval(interval);
        // interval = setInterval(() => {
        //   sendData({ type: "monitor-games" });
        // }, 1000);
      };
      ws.onmessage = (data) => {
        console.log("Football server on message");
        try {
          let obj = JSON.parse(data.data);
          if (obj) {
            switch (obj.type) {
              case "update_goal":
                if (obj.data) {
                  console.log("update_goal");
                  let newGame = { ...game };
                  _.set(newGame, obj.data.variable, obj.data.data);

                  setGame(newGame);
                }
                break;
              case "game":
                if (obj.data && game) {
                  let newGame = { ...game };
                  if (obj.data.variable) {
                    _.set(newGame, obj.data.variable, obj.data.data);
                  } else {
                    delete obj.data.stats;
                    newGame = {
                      ...newGame,
                      ...obj.data,
                    };
                  }

                  setGame(newGame);
                }

                break;
              case "subscribed":
                if (obj.data) {
                  if (JSON.stringify(game) !== JSON.stringify(obj.data)) {
                    let newGame = {
                      ...obj.data,
                      stats: game?.stats || {
                        home_team: {},
                        away_team: {},
                      },
                    };

                    setGame(newGame);
                  }
                }
                break;

              case "stats":
                if (obj.data && game) {
                  if (!obj.data?.home_team) {
                    obj.data.home_team = {};
                  }
                  if (!obj.data?.away_team) {
                    obj.data.away_team = {};
                  }
                  setGame({ ...game, stats: obj.data });
                }

                break;
              case "monitor-games":
                setMonitorGames(obj.data);
                break;
              case "monitor-machine":
                let newMachines = [...monitorMachines];
                let index = newMachines.findIndex(
                  (m) => m.machine === obj.data.machine || m.ip == obj.data.ip
                );
                if (index > -1) {
                  newMachines[index] = obj.data;
                } else {
                  newMachines.push(obj.data);
                }
                setMonitorMachines(newMachines);
                break;
              case "welcome":
                setServer({ ...server, ...obj.data, connected: true });
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      ws.onerror = (err) => {
        setServer({ ...server, connected: false });
        console.log("Football server on error");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        setServer({ ...server, connected: false });
        console.log("Football server on close");
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws, game, monitorMachines]);

  useEffect(() => {
    if (ws) {
      ws.addEventListener("message", processData);
      function processData(data) {
        try {
          let obj = JSON.parse(data.data);
          if (obj) {
            switch (obj.type) {
              case "log":
                setLogs([...logs, obj.data]);
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      }

      return () => {
        ws.removeEventListener("message", processData);
      };
    }
  }, [ws, logs]);

  function sendData(data) {
    try {
      if (ws) {
        ws.send(JSON.stringify(data));
      }
    } catch (err) {}
  }

  function subscribeToGame(id) {
    setSubscribedGameId(id);
    sendData({ type: "subscribe-game", id: id });
  }

  function subscribeToMonitor() {
    sendData({ type: "subscribe-monitor" });
  }

  return (
    <SocketContext.Provider
      value={{
        game,
        subscribeToGame,
        logs,
        sendData,
        monitorGames,
        server,
        monitorMachines,
        subscribeToMonitor,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };

import React, { useContext, useState, useEffect } from "react";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
import Input from "../../UI/Form/Input";
import { SocketContext } from "../../../contexts/SocketContext";
import { HiSwitchHorizontal } from "react-icons/hi";
import { UIContext } from "../../../contexts/UIContext";

const Row = styled.div`
  display: flex;
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
  margin-right: auto;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const SwitchButton = styled(Button)`
  height: 40px;
  font-size: 1em;
  margin-right: 0px;
  width: 33%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  th:nth-child(1) {
    width: 65px;
  }
  th:nth-child(2) {
    width: 65px;
  }
  th:nth-child(3) {
    width: ${(props) => (props.showNumber ? "65px" : "")};
  }
  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
  }
  td.icons {
    padding: 0px;
    font-size: 20px;
    padding-top: 3px;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function OPTAPossession({
  fixture,
  possession,
  home_team,
  away_team,
}) {
  if (!fixture) {
    return <></>;
  }
  let ballPossession = possession?.possessionWave?.find(
    (p) => p.type === "ballPossession"
  );

  return (
    <Panel>
      <PanelTitle title="Possession"></PanelTitle>
      <PanelContent
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Table>
          <thead>
            <tr>
              <TH>Last X</TH>
              <TH>{home_team?.code}</TH>
              <TH>{away_team?.code}</TH>
            </tr>
          </thead>
          <tbody>
            {ballPossession?.last?.map((interval) => {
              return (
                <tr>
                  <td>{interval.type}</td>
                  <td>{parseInt(interval.home)}</td>
                  <td>{100 - parseInt(interval.home)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </PanelContent>
    </Panel>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin: 1em;
  flex-direction: column;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  padding: 0.5em;
  width: 100%;
  font-size: 0.8em;
  font-weight: 100;
  align-items: center;
  :nth-of-type(even) {
    background-color: #e8e8e8;
  }
`;
const Label = styled.div`
  display: flex;
  min-width: 150px;
`;

const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #b1b1b1;
  height: 25px;
  background-color: #f1f1f1;
  padding-left: 0.5em;
  :focus {
    outline: none;
    border: 1px solid #14ec8e;
  }
`;

const Button = styled.div`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 4px 0px rgb(0 0 0 / 46%);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) => (props.green ? "#14ec8e" : "#fff")};
  color: ${(props) => (props.green ? "#545454" : "")};
`;
const SavePanel = styled.div`
  display: flex;
  bottom: 0;
  padding-bottom: 1em;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
`;
export default function Player() {
  const [selectedPlayer, setSelectedPlayer] = useState();
  const { getPlayer, updatePlayer } = useContext(DataContext);
  const [update, setUpdate] = useState(false);
  const [saved, setSaved] = useState(false);
  const { playerId } = useParams();
  useEffect(() => {
    getPlayer({ playerId: playerId }).then((data) => {
      setSelectedPlayer(data);
    });
  }, [playerId]);

  function save() {
    updatePlayer(selectedPlayer).then((data) => {
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 2000);
    });
  }

  return (
    <Main>
      {selectedPlayer && (
        <Content>
          <SavePanel>
            <Button>Cancel</Button>
            <Button
              green
              onClick={() => {
                save();
              }}
            >
              Save
            </Button>
            {saved && "Saved!"}
          </SavePanel>
          <Row>
            <Label>First Name</Label>
            <Input
              id="player.first"
              autocomplete="player.first"
              value={selectedPlayer.first_name}
              onChange={(e) => {
                selectedPlayer.first_name = e.target.value;
                setUpdate(!update);
              }}
            />
          </Row>
          <Row>
            <Label>Last Name</Label>
            <Input
              id="player.last"
              autocomplete="player.last"
              value={selectedPlayer.last_name}
              onChange={(e) => {
                selectedPlayer.last_name = e.target.value;
                setUpdate(!update);
              }}
            />
          </Row>
        </Content>
      )}
    </Main>
  );
}

import React from "react";
import styled from "styled-components";
import Image from "../Image";

const Main = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  display: flex;
  border: 1px solid #dee2e6;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
  background-color: #fff;
`;

export default function Headshot({ image, style }) {
  return (
    <Main style={style}>
      <Image
        style={{ height: "100%", width: "auto" }}
        url={image}
        placeholder={"/HeadPlaceholder.png"}
      />
      {/* <img
        src={image}
        style={{ height: "100%", width: "auto" }}
        onError={(e) => {
          e.target.src = "/HeadPlaceholder.png";
        }}
      /> */}
    </Main>
  );
}

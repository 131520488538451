import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import SettingEdit from "../Settings/SettingEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function SettingEditor({
  setting,
  onSave,
  onCancel,
  selectSetting,
}) {
  const { updateSettings, deleteSettings } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={setting}
      onCancel={() => {
        selectSetting();
        onCancel();
      }}
    >
      {setting && (
        <Panel
          style={{ height: "350px", marginTop: "-25%", overflow: "hidden" }}
        >
          <PanelTitle title={"setting Edit"}>
            <div>{setting._id}</div>
          </PanelTitle>
          <PanelContent>
            <SettingEdit
              setting={setting}
              onChange={(s) => {
                selectSetting(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={true}
            onDelete={() => {
              deleteSettings({ data: setting }).then(() => {
                selectSetting();
                onSave();
              });
            }}
            onSave={() => {
              updateSettings({ data: setting }).then(() => {
                selectSetting();
                onSave();
              });
            }}
            onCancel={() => {
              selectSetting();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}

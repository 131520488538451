import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import SeasonEditor from "../Modals/SeasonEditor";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function SeasonsList() {
  const { getSeason, getCompetition, getTeams } = useContext(DataContext);
  const [seasons, setSeasons] = useState();
  const [teams, setTeams] = useState();
  const [competitions, setCompetitions] = useState();
  const [selectedSeason, setSelectedSeason] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getCompetition({}).then((competitions) => {
      setCompetitions(competitions);
      getSeason().then((data) => {
        setSeasons(
          [...data].sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        );
      });
    });
  }
  return (
    <>
      <Button
        green
        style={{ margin: "1em", width: "200px" }}
        onClick={() => {
          setSelectedSeason({ teams: [] });
        }}
      >
        + Create Season
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Season List"} />
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH style={{ width: "200px" }}>ID</TH>
                <TH style={{ width: "200px" }}>OPTA ID</TH>
                <TH style={{ width: "200px" }}>Competition</TH>
                <TH style={{ width: "100px" }}>Season</TH>
                {/* <TH>Teams</TH> */}
              </tr>
            </thead>
            <tbody>
              {seasons &&
                seasons.map((season) => {
                  return (
                    <tr
                      onClick={() => {
                        setSelectedSeason(season);
                      }}
                    >
                      <td>{season._id}</td>
                      <td>{season.opta_ID}</td>
                      <td>{season.competition?.name}</td>
                      <td>{season.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedSeason && (
        <SeasonEditor
          competitions={competitions}
          season={selectedSeason}
          teams={teams}
          selectSeason={(s) => {
            setSelectedSeason(s);
          }}
          onCancel={() => {
            setSelectedSeason();
          }}
          onSave={() => {
            setSelectedSeason();
            getData();
          }}
        />
      )}
    </>
  );
}

import React, { useContext, useState } from "react";
import Input from "../UI/Form/Input";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import styled from "styled-components";
import PanelTitle from "../UI/Panel/PanelTitle";
import { Button } from "../../Theme/Hyper";
import { DataContext } from "../../contexts/DataContext";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Get = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

const Pre = styled.pre`
  white-space: pre-wrap;
  width: 80%;
`;
export default function Opta() {
  const { testOPTA } = useContext(DataContext);
  const [response, setResponse] = useState();
  const [url, setUrl] = useState(
    "https://secure.omo.akamai.opta.net/?user=5kYs0c1AL&psw=6SDTyPqQ4g4Ftfz2&feed_type=F9&game_id=2129543"
  );
  return (
    <Main>
      <Panel>
        <PanelTitle title={"Response"} />
        <PanelContent style={{ alignItems: "center", flexDirection: "column" }}>
          <Get>
            GET
            <Input
              value={url}
              onChange={(e) => {
                setUrl(e.currentTarget.value);
              }}
            />
            <Button
              onClick={() => {
                setResponse();
                testOPTA({ data: { url: url } })
                  .then((response) => response.json())
                  .then((response) => {
                    if (typeof response == "string") {
                      setResponse({
                        body: JSON.stringify(response, null, 2),
                      });
                    } else {
                      setResponse({
                        body: JSON.stringify(response, null, 2),
                      });
                    }
                  })
                  .catch((err) => {
                    debugger;
                    setResponse(err.toString());
                  });
              }}
            >
              Send
            </Button>
          </Get>

          <Pre>{response && response.body}</Pre>
        </PanelContent>
      </Panel>
    </Main>
  );
}

import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
const Main = styled.div`
  width: 500px;
  height: calc(100vh - 40px);
  border-right: 1px solid #dadde1;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0.5rem;
  color: #606770;
`;
const Ul = styled.ul`
  transition: height var(--ifm-transition-fast)
    cubic-bezier(0.08, 0.52, 0.52, 1);
  margin: 0;
  list-style-type: none;
  padding-left: 0;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 2em;
  }
`;
const A = styled.a`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#0000000d" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;
const FirstName = styled.div`
  display: flex;
  min-width: 200px;
`;
const LastName = styled.div`
  font-weight: 500;
  display: flex;
  min-width: 200px;
`;

export default function PlayerList({ players, onSelect, selectedPlayer }) {
  let playerSorted = players.sort((a, b) => {
    if (a.last_name > b.last_name) {
      return 1;
    } else if (a.last_name < b.last_name) {
      return -1;
    }
    if (a.first_name > b.first_name) {
      return 1;
    } else if (a.first_name < b.first_name) {
      return -1;
    }
  });

  return (
    <Main>
      <Ul>
        {playerSorted &&
          playerSorted.map((player) => {
            return (
              <Li onClick={() => onSelect(player)}>
                <A
                  href="#"
                  selected={player === selectedPlayer}
                  selectedBG={player === selectedPlayer}
                >
                  <LastName>{player.last_name}</LastName>
                  <FirstName>{player.first_name}</FirstName>
                </A>
              </Li>
            );
          })}
      </Ul>
    </Main>
  );
}

import React from "react";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  background-color: #fff;
  padding: 1em;
  border-radius: 4px;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: 400px;
  top: 86px;
  animation: "updown" 3s;
  animation-iteration-count: infinite;
  :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
    top: -20px;
  }

  @keyframes updown {
    0% {
      top: 100px;
    }
    50% {
      top: 66px;
    }
    100% {
      top: 100px;
    }
  }
`;

export default function Balloon({ children }) {
  return <Main>{children}</Main>;
}

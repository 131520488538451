import React from "react";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Role = styled.span`
  border-right: 1px solid #929292;
  padding-left: 5px;
  padding-right: 5px;
`;
export default function UsersList({ users, onSelect }) {
  return (
    <Panel style={{ marginTop: "1em" }}>
      <PanelTitle title={"User List"} />
      <PanelContent>
        <Table>
          <thead>
            <tr>
              <TH>ID</TH>
              <TH>Username</TH>
              <TH>Name</TH>

              <TH>Roles</TH>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => {
              return (
                <tr
                  onClick={() => {
                    onSelect(user);
                  }}
                >
                  <td>{user._id}</td>
                  <td>{user.username}</td>
                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                  <td>
                    {user.roles?.map((role) => {
                      return <Role>{role}</Role>;
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </PanelContent>
    </Panel>
  );
}

import React from "react";
import { Button } from "../../../Theme/Hyper";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  margin-top: auto;
`;
export default function ProgressButtons({
  stage,
  total,
  onNext,
  onPrevious,
  onSave,
  edit,
}) {
  return (
    <Main>
      {stage > 0 && (
        <Button style={{ marginRight: "auto" }} onClick={onPrevious}>
          Prev
        </Button>
      )}
      {stage < total - 1 && (
        <Button style={{ marginLeft: "auto" }} green onClick={onNext}>
          Next
        </Button>
      )}
      {stage === total - 1 && (
        <Button style={{ marginLeft: "auto" }} green onClick={onSave}>
          {edit ? "Save" : "Create"}
        </Button>
      )}
    </Main>
  );
}

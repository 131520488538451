import React, { useEffect, useState } from "react";
import Grid from "styled-components-grid";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import moment from "moment";
import styled from "styled-components";
import { ImCog } from "react-icons/im";
import { Button } from "../../../Theme/Hyper";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
`;
export default function ManualBox({ box, onDelete, onSelect }) {
  return (
    <Panel>
      <PanelTitle title={box.name}>
        <Buttons>
          <Button
            red
            onClick={() => {
              onDelete();
            }}
          >
            Delete
          </Button>
          <ImCog
            onClick={() => {
              onSelect(box);
            }}
          />
        </Buttons>
      </PanelTitle>
      <PanelContent>
        <Main>
          <Grid>
            <Grid.Unit size={1 / 1}>
              <Grid>
                <Grid.Unit size={1 / 10}>{}</Grid.Unit>
                <Grid.Unit size={9 / 10}>{box && box.first_name}</Grid.Unit>
              </Grid>
            </Grid.Unit>
            <Grid.Unit size={1 / 1}>
              <Grid>
                <Grid.Unit size={1 / 10}>{}</Grid.Unit>
                <Grid.Unit size={9 / 10}>{box && box.heading}</Grid.Unit>
              </Grid>
            </Grid.Unit>
            <Grid.Unit size={1 / 1}>
              <Grid>
                <Grid.Unit size={1 / 10}>{}</Grid.Unit>
                <Grid.Unit size={5 / 10}>{box && box.subtitle}</Grid.Unit>
                <Grid.Unit size={1 / 10}>
                  {box && box.number_columns >= 1 && box.column_1}
                </Grid.Unit>
                <Grid.Unit size={1 / 10}>
                  {box && box.number_columns >= 2 && box.column_2}
                </Grid.Unit>
                <Grid.Unit size={1 / 10}>
                  {box && box.number_columns >= 3 && box.column_3}
                </Grid.Unit>
                <Grid.Unit size={1 / 10}>
                  {box && box.number_columns >= 4 && box.column_4}
                </Grid.Unit>
              </Grid>
            </Grid.Unit>
          </Grid>

          <Rows>
            {box &&
              box.rows &&
              box.rows.slice(0, box.number_rows).map((row) => {
                return (
                  <Grid>
                    <Grid.Unit size={1 / 10}>{}</Grid.Unit>
                    <Grid.Unit size={1 / 10}>{row.number}</Grid.Unit>
                    <Grid.Unit size={4 / 10}>{row.text}</Grid.Unit>
                    <Grid.Unit size={1 / 10}>
                      {box.number_columns >= 1 && row.column_1}
                    </Grid.Unit>
                    <Grid.Unit size={1 / 10}>
                      {box.number_columns >= 2 && row.column_2}
                    </Grid.Unit>
                    <Grid.Unit size={1 / 10}>
                      {box.number_columns >= 3 && row.column_3}
                    </Grid.Unit>
                    <Grid.Unit size={1 / 10}>
                      {box.number_columns >= 4 && row.column_4}
                    </Grid.Unit>
                  </Grid>
                );
              })}
          </Rows>
        </Main>
      </PanelContent>
    </Panel>
  );
}

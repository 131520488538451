import React from "react";
import styled from "styled-components";

const Main = styled.div`
  box-shadow: inset 0px 0px 11px 2px rgb(47 47 47 / 44%);
  background-color: #293b52;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fdfdfd;
  padding: 1em;
  margin-bottom: 1em;
`;

export default function Hero({ children, style }) {
  return <Main style={style}>{children}</Main>;
}

import React, { useEffect } from "react";
import Grid from "styled-components-grid";
import AttackingAreas from "./StatEditor/AttackingAreas";
import ActionAreas from "./StatEditor/ActionAreas";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import Stats from "./Stats";
import CornerBoxes from "../CornerBoxes/CornerBoxes";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import PlayerStats from "./PlayerStats";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import OPTAActionAreas from "./StatEditor/OPTAActionAreas";
import OPTAPossession from "./StatEditor/OPTAPossession";
import AutoCB from "./AutoCB/AutoCB";
import MatchFacts from "./AutoCB/MatchFacts";

const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#606770"};
  background-color: ${(props) => (props.selectedBG ? "#dee2e6" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #0000000d;
  }
`;

const StatsNavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0.5em;
`;

export default function LivePage({
  fixture,
  home_team,
  away_team,
  game,
  players,
}) {
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const { getSquad, getPossession } = useContext(DataContext);
  const [homeSquad, setHomeSquad] = useState([]);
  const [awaySquad, setAwaySquad] = useState([]);
  const [possession, setPossession] = useState({});

  const [selectedView, setSelectedView] = useState(
    location.pathname.indexOf("/live/auto_cb") > -1
      ? "auto_cb"
      : location.pathname.indexOf("/live/player") > -1
      ? "player"
      : "team"
  );
  const { selectedSeason } = useContext(UIContext);

  useEffect(() => {
    if (fixture) {
      getSquad({
        teamId: fixture[`home_team`],
        seasonId: selectedSeason._id,
      }).then((data) => {
        setHomeSquad(data);
      });
      getSquad({
        teamId: fixture[`away_team`],
        seasonId: selectedSeason._id,
      }).then((data) => {
        setAwaySquad(data);
      });
    }
  }, [fixture, game, home_team, away_team, selectedSeason]);

  useEffect(() => {
    let interval;
    if (fixture) {
      getPossession({ id: fixture.opta_ID }).then((data) => {
        setPossession(data);
      });
      interval = setInterval(() => {
        getPossession({ id: fixture.opta_ID }).then((data) => {
          setPossession(data);
        });
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [fixture]);

  return (
    <>
      <StatsNavList>
        <li>
          <A
            selected={selectedView == "team"}
            selectedBG={selectedView == "team"}
            onClick={() => {
              setSelectedView("team");
            }}
            to={`${url}`}
          >
            Team Stats
          </A>
        </li>
        <li>
          <A
            selected={selectedView == "auto_cb"}
            selectedBG={selectedView == "auto_cb"}
            onClick={() => {
              setSelectedView("auto_cb");
            }}
            to={`${url}/auto_cb`}
          >
            Auto CB
          </A>
        </li>
        {/* <li>
          <A
            selected={selectedView == "player"}
            selectedBG={selectedView == "player"}
            onClick={() => {
              setSelectedView("player");
            }}
            to={`${url}/player`}
          >
            Player Stats
          </A>
        </li> */}
      </StatsNavList>
      <Switch>
        <Route path={`${path}/auto_cb`}>
          <MatchFacts
            game={game}
            home_team={home_team}
            away_team={away_team}
            homeSquad={homeSquad}
            awaySquad={awaySquad}
            text="Match Facts"
          />
          <Grid style={{ marginBottom: "1em" }}>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"totalScoringAtt"}
                text="shots"
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"ontargetScoringAtt"}
                text="on target"
              />
            </Grid.Unit>
          </Grid>
          <Grid style={{ marginBottom: "1em" }}>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"touches"}
                text="touches"
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"accuratePass"}
                text="successful passes"
              />
            </Grid.Unit>
          </Grid>
          <Grid style={{ marginBottom: "1em" }}>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"totalCross"}
                text="crosses"
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"totalTackle"}
                text="tackles"
              />
            </Grid.Unit>
          </Grid>
          <Grid style={{ marginBottom: "1em" }}>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"interception"}
                text="interceptions"
              />
            </Grid.Unit>
            <Grid.Unit size={1 / 2}>
              <AutoCB
                game={game}
                home_team={home_team}
                away_team={away_team}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
                variable={"fouls"}
                text="fouls"
              />
            </Grid.Unit>
          </Grid>
        </Route>
        <Route path={`${path}/player`}>
          <Panel>
            <PanelTitle title="Player Stats"></PanelTitle>
            <PanelContent
              style={{
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PlayerStats
                game={fixture}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
              />
            </PanelContent>
          </Panel>
        </Route>
        <Route path={`${path}/`}>
          <Grid>
            <Grid.Unit size={3 / 12}>
              <Grid>
                <Grid.Unit size={1 / 1}>
                  <ActionAreas
                    fixture={fixture}
                    home_team={home_team}
                    away_team={away_team}
                  />
                </Grid.Unit>
                <Grid.Unit size={1 / 1} style={{ marginTop: "1em" }}>
                  <OPTAPossession
                    possession={possession[0]}
                    fixture={fixture}
                    home_team={home_team}
                    away_team={away_team}
                  />
                </Grid.Unit>
                <Grid.Unit size={1 / 1} style={{ marginTop: "1em" }}>
                  <OPTAActionAreas
                    possession={possession[0]}
                    fixture={fixture}
                    home_team={home_team}
                    away_team={away_team}
                  />
                </Grid.Unit>
              </Grid>
            </Grid.Unit>
            <Grid.Unit size={3 / 12}>
              <Grid>
                <Grid.Unit size={1 / 1}>
                  <AttackingAreas
                    possession={possession}
                    fixture={fixture}
                    home_team={home_team}
                    away_team={away_team}
                  />
                </Grid.Unit>
              </Grid>
            </Grid.Unit>
            <Grid.Unit size={6 / 12}>
              <Panel>
                <PanelTitle title="Team Stats"></PanelTitle>
                <PanelContent
                  style={{
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Stats game={game} />
                </PanelContent>
              </Panel>
            </Grid.Unit>
            <Grid.Unit size={4 / 12}>
              <Panel>
                <PanelTitle title="Corner Boxes"></PanelTitle>
                <PanelContent
                  style={{
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CornerBoxes fixture={fixture} />
                </PanelContent>
              </Panel>
            </Grid.Unit>
          </Grid>
        </Route>
      </Switch>
    </>
  );
}

import React from "react";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3em;
  height: 50px;
  background-color: ${(props) => (props.current ? "#6de2af" : "#e9ecef")};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;
const Step = styled.div`
  width: 50px;
  height: 100%;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  color: #868e96;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.current ? "#12ca7a" : "#dee2e6")};
  color: ${(props) => (props.current ? "#fff" : "")};
  align-items: center;
  transition: all 0.2s ease-in-out;
`;
const Title = styled.div`
  padding-left: 1em;
  padding-right: 0.5em;
  color: ${(props) => (props.current ? "#fff" : "")};
  height: 100%;
  align-items: center;
  display: flex;
  border-radius: 2px;
  min-width: 133px;
  transition: all 0.2s ease-in-out;
`;
export default function Stage({ title, stage, current, onClick }) {
  return (
    <Main
      current={current}
      onClick={() => {
        onClick(stage);
      }}
    >
      <Step current={current}>{stage}</Step>
      <Title current={current}>{title}</Title>
    </Main>
  );
}

import React, { useContext } from "react";
import { SocketContext } from "../../contexts/SocketContext";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  background-color: white;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;

  :hover {
    background-color: #293b52;
    color: #fff;
  }
`;
export default function OPTAF28() {
  const { game } = useContext(SocketContext);
  if (game) {
    debugger;
  }
  return (
    <Column>
      <Main>
        {game?.opta_F28?.PossessionWave?.map((wave) => {
          return (
            <Column style={{ width: "30%", margin: "1em" }}>
              <Row>
                <Column style={{ width: "200px" }}>{wave.Type}</Column>
                <Column style={{ width: "200px" }}>Home</Column>
                {wave.Type === "TerritorialThird" && (
                  <Column style={{ width: "200px" }}>Middle</Column>
                )}
                <Column style={{ width: "200px" }}>Away</Column>
              </Row>

              {wave.Intervals?.IntervalLength?.map((length) => {
                if (Array.isArray(length.Interval)) {
                  return (
                    <div>
                      {length.Type}' Interval
                      {length.Interval?.map((interval) => {
                        return (
                          <Row>
                            <Column style={{ width: "200px" }}>
                              {interval.Type}
                            </Column>
                            <Column style={{ width: "200px" }}>
                              {interval.Home}
                            </Column>
                            {wave.Type === "TerritorialThird" && (
                              <Column style={{ width: "200px" }}>
                                {interval.Middle}
                              </Column>
                            )}
                            <Column style={{ width: "200px" }}>
                              {interval.Away}
                            </Column>
                          </Row>
                        );
                      })}
                    </div>
                  );
                } else {
                  return (
                    <div>
                      {length.Type}' Interval
                      <Row>
                        <Column style={{ width: "200px" }}>
                          {length.Interval?.Type}
                        </Column>
                        <Column style={{ width: "200px" }}>
                          {length.Interval?.Home}
                        </Column>
                        {wave.Type === "TerritorialThird" && (
                          <Column style={{ width: "200px" }}>
                            {length.Interval?.Middle}
                          </Column>
                        )}
                        <Column style={{ width: "200px" }}>
                          {length.Interval?.Away}
                        </Column>
                      </Row>
                    </div>
                  );
                }
              })}
            </Column>
          );
        })}
      </Main>
      <Main>
        {game?.opta_F28?.PossessionWave?.map((wave) => {
          return (
            <Column style={{ width: "30%", margin: "1em" }}>
              {wave.Type}

              {Array.isArray(wave.LastX?.Last) &&
                wave.LastX?.Last?.map((length) => {
                  return (
                    <div>
                      <Row>
                        <Column style={{ width: "200px" }}>
                          Last {length.Type}
                        </Column>
                        <Column style={{ width: "200px" }}>
                          {length.Home}
                        </Column>
                        {wave.Type === "TerritorialThird" && (
                          <Column style={{ width: "200px" }}>
                            {length.Middle}
                          </Column>
                        )}
                        <Column style={{ width: "200px" }}>
                          {length.Away}
                        </Column>
                      </Row>
                    </div>
                  );
                })}
              {!Array.isArray(wave.LastX?.Last) && (
                <div>
                  <Row>
                    <Column style={{ width: "200px" }}>
                      Last {wave.LastX?.Last?.Type}
                    </Column>
                    <Column style={{ width: "200px" }}>
                      {wave.LastX?.Last?.Home}
                    </Column>
                    {wave.Type === "TerritorialThird" && (
                      <Column style={{ width: "200px" }}>
                        {wave.LastX?.Last?.Middle}
                      </Column>
                    )}
                    <Column style={{ width: "200px" }}>
                      {wave.LastX?.Last?.Away}
                    </Column>
                  </Row>
                </div>
              )}
            </Column>
          );
        })}
      </Main>
      <Main>
        {game?.opta_F28?.PossessionWave?.map((wave) => {
          return (
            <Column style={{ width: "30%", margin: "1em" }}>
              {wave.Type}

              <div>
                <Row>
                  <Column style={{ width: "200px" }}>Overall</Column>
                  <Column style={{ width: "200px" }}>
                    {wave.Overall?.Home}
                  </Column>
                  {wave.Type === "TerritorialThird" && (
                    <Column style={{ width: "200px" }}>
                      {wave.Overall?.Middle}
                    </Column>
                  )}
                  <Column style={{ width: "200px" }}>
                    {wave.Overall?.Away}
                  </Column>
                </Row>
              </div>
            </Column>
          );
        })}
      </Main>
    </Column>
  );
}

import React, { useContext, useState, useEffect } from "react";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
import Input from "../../UI/Form/Input";
import { SocketContext } from "../../../contexts/SocketContext";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { UIContext } from "../../../contexts/UIContext";

const Row = styled.div`
  display: flex;
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
  margin-right: auto;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const DirectionButton = styled(Button)`
  font-size: 1.2em;
`;
export default function AttackingAreas({ fixture }) {
  const { game, sendData } = useContext(SocketContext);
  const { selectedCompetition } = useContext(UIContext);

  const [view, setView] = useState(0);

  if (!fixture) {
    return <></>;
  }
  return (
    <Panel>
      <PanelTitle title="Attacking Areas"></PanelTitle>
      <PanelContent
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <opta-widget
          widget="attacking_thirds"
          competition={selectedCompetition.opta_core_ID}
          season="2020"
          match={fixture.opta_legacy_ID}
          template="normal"
          live="true"
          orientation="horizontal"
          side="both"
          half_pitch="false"
          one_direction="false"
          navigation="tabs_more"
          animation="false"
          show_match_header="false"
          show_halftime_score="false"
          show_crests="false"
          show_pitch_crests="true"
          show_competition_name="false"
          show_timecontrols="true"
          proportional_arrows="false"
          competition_naming="full"
          team_naming="full"
          show_date="false"
          date_format="dddd D MMMM YYYY"
          show_logo="false"
          show_title="false"
          show_live="false"
          breakpoints="0"
          sport="football"
        ></opta-widget>
        <Tabs>
          <Tab
            selected={view === 0}
            onClick={() => {
              setView(0);
            }}
          >
            1st Half
          </Tab>
          <Tab
            selected={view === 1}
            onClick={() => {
              setView(1);
            }}
          >
            2nd Half
          </Tab>
        </Tabs>

        {view === 0 && (
          <HalfData
            game={game}
            firstHalf={true}
            sendData={sendData}
            fixture={fixture}
          />
        )}
        {view === 1 && (
          <HalfData
            game={game}
            firstHalf={false}
            sendData={sendData}
            fixture={fixture}
          />
        )}
      </PanelContent>
    </Panel>
  );
}

function HalfData({ game, firstHalf, sendData, fixture }) {
  const [homeDirection, setHomeDirection] = useState();
  const [awayDirection, setAwayDirection] = useState();
  const [homeTop, setHomeTop] = useState();
  const [homeCentre, setHomeCentre] = useState();
  const [homeBottom, setHomeBottom] = useState();
  const [awayTop, setAwayTop] = useState();
  const [awayCentre, setAwayCentre] = useState();
  const [awayBottom, setAwayBottom] = useState();

  useEffect(() => {
    if (game) {
      if (firstHalf == 1) {
        if (homeTop == null) {
          setHomeTop(game?.hyper_stats?.home_team?.attacking_areas?.first?.top);
        }
        if (homeCentre == null) {
          setHomeCentre(
            game?.hyper_stats?.home_team?.attacking_areas?.first?.centre
          );
        }
        if (homeBottom == null) {
          setHomeBottom(
            game?.hyper_stats?.home_team?.attacking_areas?.first?.bottom
          );
        }
        if (homeDirection == null) {
          setHomeDirection(
            game?.hyper_stats?.home_team?.attacking_areas?.first?.direction
          );
        }
        if (awayDirection == null) {
          setAwayDirection(
            game?.hyper_stats?.away_team?.attacking_areas?.first?.direction
          );
        }
        if (awayTop == null) {
          setAwayTop(game?.hyper_stats?.away_team?.attacking_areas?.first?.top);
        }
        if (awayCentre == null) {
          setAwayCentre(
            game?.hyper_stats?.away_team?.attacking_areas?.first?.centre
          );
        }
        if (awayBottom == null) {
          setAwayBottom(
            game?.hyper_stats?.away_team?.attacking_areas?.first?.bottom
          );
        }
      } else {
        if (homeTop == null) {
          setHomeTop(
            game?.hyper_stats?.home_team?.attacking_areas?.second?.top
          );
        }
        if (homeCentre == null) {
          setHomeCentre(
            game?.hyper_stats?.home_team?.attacking_areas?.second?.centre
          );
        }
        if (homeBottom == null) {
          setHomeBottom(
            game?.hyper_stats?.home_team?.attacking_areas?.second?.bottom
          );
        }
        if (homeDirection == null) {
          setHomeDirection(
            game?.hyper_stats?.home_team?.attacking_areas?.second?.direction
          );
        }
        if (awayDirection == null) {
          setAwayDirection(
            game?.hyper_stats?.away_team?.attacking_areas?.second?.direction
          );
        }
        if (awayTop == null) {
          setAwayTop(
            game?.hyper_stats?.away_team?.attacking_areas?.second?.top
          );
        }
        if (awayCentre == null) {
          setAwayCentre(
            game?.hyper_stats?.away_team?.attacking_areas?.second?.centre
          );
        }
        if (awayBottom == null) {
          setAwayBottom(
            game?.hyper_stats?.away_team?.attacking_areas?.second?.bottom
          );
        }
      }
    }
  });

  function updateData({ variable, data, team }) {
    if (firstHalf) {
      variable = "hyper_stats." + team + ".attacking_areas.first." + variable;
    } else {
      variable = "hyper_stats." + team + ".attacking_areas.second." + variable;
    }
    sendData({
      type: "update_game",
      _id: fixture._id,
      variable: variable,
      data: data,
    });
  }

  return (
    <>
      <Row>
        <Half>
          {game?.home_team?.short_name}
          <Row
            style={{
              alignItems: "center",
              marginBottom: ".5em",
              justifyContent: "left",
              width: "100%",
              placeContent: "space-around",
            }}
          >
            <DirectionButton
              style={{ minWidth: "30%" }}
              green={homeDirection === "left"}
              onClick={() => {
                setHomeDirection("left");
                updateData({
                  variable: "direction",
                  data: "left",
                  team: "home_team",
                });
              }}
            >
              <BsArrowLeft />
            </DirectionButton>
            <DirectionButton
              style={{ minWidth: "30%" }}
              green={homeDirection === "right"}
              onClick={() => {
                setHomeDirection("right");
                updateData({
                  variable: "direction",
                  data: "right",
                  team: "home_team",
                });
              }}
            >
              <BsArrowRight />
            </DirectionButton>
          </Row>
          <Input
            title={"Right"}
            style={{ width: "30%" }}
            value={homeTop}
            onChange={(e) => {
              setHomeTop(e.currentTarget.value);
              updateData({
                variable: "top",
                data: e.currentTarget.value,
                team: "home_team",
              });
            }}
          />
          <Input
            title={"Middle"}
            style={{ width: "30%" }}
            value={homeCentre}
            onChange={(e) => {
              setHomeCentre(e.currentTarget.value);
              updateData({
                variable: "centre",
                data: e.currentTarget.value,
                team: "home_team",
              });
            }}
          />
          <Input
            title={"Left"}
            style={{ width: "30%" }}
            value={homeBottom}
            onChange={(e) => {
              setHomeBottom(e.currentTarget.value);
              updateData({
                variable: "bottom",
                data: e.currentTarget.value,
                team: "home_team",
              });
            }}
          />
        </Half>
        <Half>
          {game?.away_team?.short_name}
          <Row
            style={{
              alignItems: "center",
              marginBottom: ".5em",
              justifyContent: "left",
              width: "100%",
              placeContent: "space-around",
            }}
          >
            <DirectionButton
              style={{ minWidth: "30%" }}
              green={awayDirection === "left"}
              onClick={() => {
                setAwayDirection("left");
                updateData({
                  variable: "direction",
                  data: "left",
                  team: "away_team",
                });
              }}
            >
              <BsArrowLeft />
            </DirectionButton>
            <DirectionButton
              style={{ minWidth: "30%" }}
              green={awayDirection === "right"}
              onClick={() => {
                setAwayDirection("right");
                updateData({
                  variable: "direction",
                  data: "right",
                  team: "away_team",
                });
              }}
            >
              <BsArrowRight />
            </DirectionButton>
          </Row>
          <Input
            title={"Right"}
            style={{ width: "30%" }}
            value={awayTop}
            onChange={(e) => {
              setAwayTop(e.currentTarget.value);
              updateData({
                variable: "top",
                data: e.currentTarget.value,
                team: "away_team",
              });
            }}
          />
          <Input
            title={"Middle"}
            style={{ width: "30%" }}
            value={awayCentre}
            onChange={(e) => {
              setAwayCentre(e.currentTarget.value);
              updateData({
                variable: "centre",
                data: e.currentTarget.value,
                team: "away_team",
              });
            }}
          />
          <Input
            title={"Left"}
            style={{ width: "30%" }}
            value={awayBottom}
            onChange={(e) => {
              setAwayBottom(e.currentTarget.value);
              updateData({
                variable: "bottom",
                data: e.currentTarget.value,
                team: "away_team",
              });
            }}
          />
        </Half>
      </Row>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Grid from "styled-components-grid";
import Panel from "../../UI/Panel/Panel";
import PanelContent from "../../UI/Panel/PanelContent";
import PanelTitle from "../../UI/Panel/PanelTitle";
import moment from "moment";
import styled from "styled-components";
import { ImCog } from "react-icons/im";

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default function NextFive({ fixture, teams, team }) {
  const [fixtures, setFixtures] = useState();

  useEffect(() => {
    if (fixture && teams && team) {
      getData();
    }
  }, [fixture, teams, team]);

  function getData() {
    if (fixture) {
      let url =
        window.location.protocol +
        "//" +
        window.SERVER +
        ':8082/games?$filter=season $eq "' +
        fixture.season +
        '" $and (home_team $eq "' +
        team._id +
        '"$or away_team $eq "' +
        team._id +
        '")&$orderby=date,time';

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          data = data.filter(
            (d) => moment(d.date).diff(fixture.date, "days") > 0
          );
          data = data.slice(0, 5);
          setFixtures(data);
        });
    }
  }
  return (
    <Panel>
      <PanelTitle title={`${team && team.name} Next 5`}>
        <ImCog onClick={() => {}} />
      </PanelTitle>
      <PanelContent>
        <Rows>
          {fixtures &&
            fixtures.map((game) => {
              let t = teams && teams.find((t) => t._id === game.home_team);
              if (t._id === team._id) {
                t = teams.find((t) => t._id === game.away_team);
              }
              return (
                <Grid>
                  <Grid.Unit size={2 / 6}>
                    {moment(game.date).format("MMM D")}
                  </Grid.Unit>
                  <Grid.Unit size={3 / 6}>{t && t.short_name}</Grid.Unit>
                  <Grid.Unit size={1 / 6}>
                    {team._id === game.home_team ? "H" : "A"}
                  </Grid.Unit>
                </Grid>
              );
            })}
        </Rows>
      </PanelContent>
    </Panel>
  );
}

import React from "react";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
`;
const InputField = styled.input`
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #14ec8e;
    outline: none;
  }
  :disabled {
    background-color: #d3d3d3;
    color: #999da0;
  }
`;

const InputFieldMulti = styled.textarea`
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #14ec8e;
    outline: none;
  }
  :disabled {
    background-color: #d3d3d3;
    color: #999da0;
  }
`;

const Label = styled.label`
  display: flex;
  font-size: 0.8em;
  margin-bottom: 0.2em;
`;
export default function Input({
  value,
  placeholder,
  title,
  onChange,
  disabled,
  onKeyDown,
  type = "search",
  name,
  multiline = false,
}) {
  return (
    <Main>
      {title && <Label>{title}</Label>}
      {multiline && (
        <InputFieldMulti
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          name={name}
          multiline={multiline}
        />
      )}
      {!multiline && (
        <InputField
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          name={name}
          multiline={multiline}
        />
      )}
    </Main>
  );
}

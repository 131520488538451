import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext } from "../../../contexts/UIContext";
import { DataContext } from "../../../contexts/DataContext";
import { Link } from "react-router-dom";
import HyperLogo from "../../../Images/HyperLogo.png";
import { UserContext } from "../../../contexts/UserContext";

const Main = styled.div``;

const Content = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 60px;
  left: ${(props) => (props.expanded ? "0px" : "-230px")};
  bottom: 0;
  z-index: 100;
  width: 230px;
  background-color: #1d2939;
  transition: all 0.2s ease-in-out;
  padding-top: 40px;
`;

const Ul = styled.ul`
  margin: 0;

  list-style-type: none;
  padding-left: 0;
  transition: all 0.2s ease-in-out;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 0.5em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#adb5bd"};
  background-color: ${(props) => (props.selectedbg ? "#141d28" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #141d28;
    color: #fff;
  }
`;
const A_STATIC = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#adb5bd"};
  background-color: ${(props) => (props.selectedbg ? "#141d28" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #141d28;
    color: #fff;
  }
`;
const LogoContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.expanded ? "0px" : "-230px")};
  width: 230px;
  height: 60px;
  background-color: #141c27;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
  justify-content: center;
`;
const Logo = styled.img`
  height: 20px;
`;
export default function Sidebar() {
  const { navigationView, setNavigationView, showSideBar } =
    useContext(UIContext);
  const { user } = useContext(UserContext);

  return (
    <Main>
      <LogoContainer expanded={showSideBar}>
        <Logo src={HyperLogo} />
      </LogoContainer>
      <Content expanded={showSideBar}>
        <Ul>
          {user?.features?.find((f) => f.name === "football") && (
            <Li>
              <A
                href="#"
                bold="true"
                selected={
                  true ||
                  (navigationView &&
                    navigationView.split("/")[0] === "Football")
                }
                onClick={() => {
                  setNavigationView("Football/");
                }}
                to={""}
              >
                Football
                {/* <FiChevronDown /> */}
              </A>
              {/* {navigationView && navigationView.split("/")[0] === "Football" && ( */}
              <Ul>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Fixtures");
                  }}
                >
                  <A
                    to="/Football/Fixtures"
                    selected={
                      navigationView && navigationView === "Football/Fixtures"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Fixtures"
                    }
                  >
                    Fixtures
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Teams");
                  }}
                >
                  <A
                    to="/Football/Teams"
                    selected={
                      navigationView && navigationView === "Football/Teams"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Teams"
                    }
                  >
                    Teams
                  </A>
                </Li>
                {/* <Li
                onClick={() => {
                  setNavigationView("Football/Players");
                }}
              >
                <A
                  to="/Football/Players"
                  selected={
                    navigationView && navigationView === "Football/Players"
                  }
                  selectedBG={
                    navigationView && navigationView === "Football/Players"
                  }
                >
                  Players
                </A>
              </Li> */}
                <Li
                  onClick={() => {
                    setNavigationView("Football/Stadiums");
                  }}
                >
                  <A
                    to="/Football/Stadiums"
                    selected={
                      navigationView && navigationView === "Football/Stadiums"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Stadiums"
                    }
                  >
                    Stadiums
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Officials");
                  }}
                >
                  <A
                    to="/Football/Officials"
                    selected={
                      navigationView && navigationView === "Football/Officials"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Officials"
                    }
                  >
                    Officials
                  </A>
                </Li>
                {/* <Li
                onClick={() => {
                  setNavigationView("Football/Transfers");
                }}
              >
                <A
                  to="/Football/Transfers"
                  selected={
                    navigationView && navigationView === "Football/Transfers"
                  }
                  selectedBG={
                    navigationView && navigationView === "Football/Transfers"
                  }
                >
                  Transfers
                </A>
              </Li> */}
                <Li
                  onClick={() => {
                    setNavigationView("Football/Countries");
                  }}
                >
                  <A
                    to="/Football/Countries"
                    selected={
                      navigationView && navigationView === "Football/Countries"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Countries"
                    }
                  >
                    Countries
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Competitions");
                  }}
                >
                  <A
                    to="/Football/Competitions"
                    selected={
                      navigationView &&
                      navigationView === "Football/Competitions"
                    }
                    selectedbg={
                      navigationView &&
                      navigationView === "Football/Competitions"
                    }
                  >
                    Competitions
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Competitions");
                  }}
                >
                  <A
                    to="/Football/Groups"
                    selected={
                      navigationView && navigationView === "Football/Groups"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Groups"
                    }
                  >
                    Groups
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Seasons");
                  }}
                >
                  <A
                    to="/Football/Seasons"
                    selected={
                      navigationView && navigationView === "Football/Seasons"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Seasons"
                    }
                  >
                    Seasons
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("Football/Formations");
                  }}
                >
                  <A
                    to="/Football/Formations"
                    selected={
                      navigationView && navigationView === "Football/Formations"
                    }
                    selectedbg={
                      navigationView && navigationView === "Football/Formations"
                    }
                  >
                    Formations
                  </A>
                </Li>
              </Ul>
              {/* )} */}
            </Li>
          )}
          {user?.features?.find((f) => f.name === "rugby") && (
            <Li>
              <A_STATIC
                href="#"
                bold="true"
                onClick={() => {
                  window.location = window.location.origin + "/rugby";
                }}
              >
                Rugby
              </A_STATIC>
            </Li>
          )}
          {user?.features?.find((f) => f.name === "gaa") && (
            <Li>
              <A_STATIC
                href="#"
                bold="true"
                onClick={() => {
                  window.location = "https://gaa-manager.hyper.live/";
                }}
              >
                GAA
              </A_STATIC>
            </Li>
          )}
          {user?.features?.find((f) => f.name === "basketball") && (
            <Li>
              <A_STATIC
                href="#"
                bold="true"
                onClick={() => {
                  window.location = window.location.origin + "/basketball";
                }}
              >
                Basketball
              </A_STATIC>
            </Li>
          )}
        </Ul>
      </Content>
    </Main>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import Panel from "../UI/Panel/Panel";
import PanelTitle from "../UI/Panel/PanelTitle";
import PanelContent from "../UI/Panel/PanelContent";
import SkyPitch from "../../Images/SkyPitch.png";
const Main = styled.div`
  display: flex;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
  justify-content: center;
  cursor: pointer;
  padding: 0.3em;
  text-align: center;
  :hover {
    background-color: #db0a41;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
`;

const PitchContainer = styled.div`
  display: flex;
`;

const Pitch = styled.div`
  position: relative;
`;

const Player = styled.div`
  width: 23px;
  height: 21px;
  background-color: #5f5f5f;
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TR = styled.tr`
  color: ${(props) => (props.green ? "#14ec8e" : "")};
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
`;
export const Button = styled.div`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) =>
    props.green ? "#14ec8e" : props.red ? "#db0a41" : "#dee2e6"};
  color: ${(props) => (props.green || props.red ? "#fff" : "")};
  font-size: 0.8em;
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.green ? "#0ed47e" : props.red ? "#b10835" : "#dadcde"};
  }
`;

const Scroll = styled.div`
  height: 500px;
  overflow-y: auto;
`;
function SecondSpectrum({ fixture, home_team, away_team }) {
  const { getSecondSpectrumAveragePositions } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [selectedFormation, setSelectedFormation] = useState(0);

  useEffect(() => {
    getData();
    let interval = setInterval(() => {
      getData();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [fixture]);

  function getData() {
    getSecondSpectrumAveragePositions({
      gameId: fixture?.variables?.second_spectrum_id,
    }).then((data) => {
      console.table(data?.filter((i) => i.player_numbers?.length > 0)[0]);
      setData(data?.filter((i) => i.player_numbers?.length > 0));
    });
  }

  return (
    <Main>
      <Panel style={{ width: "50%" }}>
        <PanelTitle title={"Average Formations"}></PanelTitle>
        <PanelContent style={{ flexDirection: "column" }}>
          <Buttons>
            <Button
              green={selectedTeam == 0}
              onClick={() => {
                setSelectedTeam(0);
              }}
            >
              {home_team?.short_name}
            </Button>
            <Button
              green={selectedTeam == 1}
              onClick={() => {
                setSelectedTeam(1);
              }}
            >
              {away_team?.short_name}
            </Button>
          </Buttons>
          <Scroll>
            <Table>
              <tbody>
                {data
                  ?.filter((i) => (selectedTeam == 0 ? i.home : !i.home))
                  ?.map((item) => {
                    return (
                      <TR
                        green={
                          item.game_segment == selectedFormation?.game_segment
                        }
                        onClick={() => {
                          setSelectedFormation(item);
                        }}
                      >
                        <td>{item.game_segment}</td>
                      </TR>
                    );
                  })}
              </tbody>
            </Table>
          </Scroll>
        </PanelContent>
      </Panel>
      <Panel style={{ width: "50%" }}>
        <PanelTitle title={selectedFormation?.game_segment}></PanelTitle>
        <PanelContent>
          <Pitch>
            <img src={SkyPitch} />
            {selectedFormation?.player_locations?.map((player, index) => {
              return (
                <Player
                  style={{
                    left: (player[0] / 100) * 289 + "px",
                    top: 381 - (player[1] / 100) * 381 + "px",
                  }}
                >
                  {selectedFormation?.player_numbers?.[index]}
                </Player>
              );
            })}
          </Pitch>
        </PanelContent>
      </Panel>
    </Main>
  );
}

export default SecondSpectrum;

import React, { useContext, useEffect } from "react";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import { read, utils } from "xlsx";
import { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import SelectUI from "../UI/Form/SelectUI";
import CompetitionSelect from "./CreateFixture/CompetitionSelect";

const SelectContainer = styled(Select)`
  width: 40%;
  margin: 0.5em;
  width: ${(props) => (props.setSize ? "128px" : "")};
`;

const Players = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function ImportSquadFromCompModal({
  squad,
  team,
  seasonId,
  onSave,
  onCancel,
}) {
  const [competition, setCompetition] = useState();
  const [season, setSeason] = useState();
  const [players, setPlayers] = useState();
  const [competitions, setCompetitions] = useState([]);
  const { updatePlayer, addToSquad, updateSquadPlayer, getSquad } =
    useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={true}
      onCancel={() => {
        onCancel();
      }}
    >
      <Panel style={{ marginTop: "-25%" }}>
        <PanelTitle title={"Import Squad"}></PanelTitle>
        <PanelContent style={{ flexDirection: "column" }}>
          <CompetitionSelect
            competition={competition}
            season={season}
            onChange={(competition) => {
              setCompetition(competition);
              setSeason({ name: "" });
            }}
            onChangeSeason={(season) => {
              setSeason(season);
              getSquad({
                teamId: team?._id,
                seasonId: season._id,
              }).then((data) => {
                setPlayers(data);
              });
            }}
            onChangeStage={(stage) => {}}
          />
          <Players>
            {players?.map((player, index) => {
              return (
                <span>
                  {player?.shirt_number ??
                    player?.opta_shirt_number ??
                    player?.uefa_shirt_number}{" "}
                  -{" "}
                  {player.player?.first_name ??
                    player.player?.opta_first_name ??
                    player.player?.uefa_first_name}{" "}
                  {player.player?.last_name ??
                    player.player?.opta_last_name ??
                    player.player?.uefa_last_name}
                </span>
              );
            })}
          </Players>
        </PanelContent>

        <SavePanel
          saveLabel="Import"
          showDelete={false}
          onDelete={() => {}}
          onSave={() => {
            players?.forEach((player, index) => {
              let old_player = squad?.find(
                (s) =>
                  (s.shirt_number ??
                    s.opta_shirt_number ??
                    s.uefa_shirt_number) ===
                  index + 1
              );
              let promises = [];

              promises.push(
                addToSquad({
                  playerId: player.player?._id,
                  teamId: team._id,
                  seasonId: seasonId,
                  shirt_number:
                    player?.shirt_number ??
                    player?.opta_shirt_number ??
                    player?.uefa_shirt_number,
                  position: player.position,
                  type: player.type,
                })
              );
              Promise.all(promises)
                .then(() => {
                  onSave();
                })
                .catch(() => {
                  onSave();
                });
            });
          }}
          onCancel={() => {
            onCancel();
          }}
        ></SavePanel>
      </Panel>
    </Modal>
  );
}

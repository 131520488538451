import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  padding: 20px 20px;
`;
export default function PanelContent({ children, style }) {
  return <Main style={style}>{children}</Main>;
}

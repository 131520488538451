import React, { useState, useEffect } from "react";
import Games from "../Live/Games";
import styled from "styled-components";
import Game from "../Live/Game";
import { useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GAME_BY_ID = gql`
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      coverage_level
      process_opta_data
      stadium {
        _id
        opta_name
        name
      }
      competition {
        name
        _id
        competition_type
      }
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            uefa_shirt_number
            player {
              _id
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          subbed
          sub_order
          captain
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            uefa_shirt_number
            player {
              _id
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
              first_name_upper
              last_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
          subbed
          sub_order
          captain
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            uefa_shirt_number
            player {
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            uefa_shirt_number
            player {
              opta_first_name
              opta_last_name
              uefa_first_name
              uefa_last_name
              first_name
              last_name
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        code
      }
      away_team {
        _id
        name
        code
      }
      live_data
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  flex-direction: column;
`;
export default function Live({ fixtures, teams, players }) {
  const [selectedGame, setSelectedGame] = useState();
  let { gameID } = useParams();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  // useEffect(() => {
  //   let unsubscribe;
  //   if (subscribeToMore) {
  //     console.log("Subscribed");
  //     unsubscribe = subscribeToMore({
  //       document: GAME_SUBSCRIPTION,
  //       variables: { id: gameID },
  //       updateQuery: (prev, { subscriptionData }) => {
  //         if (!subscriptionData.data) return prev;
  //         const updatedGame = subscriptionData.data.gameUpdated;
  //         let newGame = {
  //           gameById: { ...prev.gameById, ...updatedGame },
  //         };
  //         return newGame;
  //       },
  //     });
  //   }
  //   return () => {
  //     console.log("Unsubscribed");
  //     unsubscribe && unsubscribe();
  //   };
  // }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setSelectedGame(data.gameById);
    }
  }, [data]);

  return (
    <Main>
      {!selectedGame && (
        <Games
          fixtures={fixtures}
          selectedGame={selectedGame}
          teams={teams}
          onSelect={setSelectedGame}
        />
      )}

      <Game players={players} teams={teams} fixture={selectedGame} />
    </Main>
  );
}

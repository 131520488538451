import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import { UIContext } from "../../contexts/UIContext";
import StadiumsList from "../Stadiums/StadiumsList";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  return (
    <Main>
      <StadiumsList />
    </Main>
  );
}

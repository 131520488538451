import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import { UIContext } from "../../contexts/UIContext";
import OfficialsList from "../Officials/OfificialsList";

const Main = styled.div`
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  const { selectedSeason } = useContext(UIContext);
  return (
    <Main>
      {/* <Button
        onClick={() => {
          fetch(
            "http://localhost:8082/opta/officials/" + selectedSeason.opta_ID
          );
        }}
      >
        Update OPTA
      </Button> */}
      <OfficialsList />
    </Main>
  );
}

import React, { useContext, useState } from "react";
import { DataContext } from "../../contexts/DataContext";

import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import CornerBoxEdit from "./CornerBoxEdit";
import XLSX from "xlsx";

export default function CornerBoxEditor({
  cornerBox,
  onCancel,
  onSave,
  onUpdate,
  onDelete,
  originalCornerBox,
  game,
  home_team,
  away_team,
}) {
  const { updateStatBox } = useContext(DataContext);

  function handleFile(e) {
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: "array" });

      let box = { ...cornerBox };
      let worksheet = workbook.Sheets["Use This One"];
      try {
        box.name = f.name.split(".")[0];
      } catch (err) {
        box.name = "";
      }
      try {
        box.title_badge = worksheet["B1"].v;
      } catch (err) {
        box.title_badge = "";
      }
      try {
        box.first_name = worksheet["B2"].v;
      } catch (err) {
        box.first_name = "";
      }
      try {
        box.heading = worksheet["B3"].v;
      } catch (err) {
        box.heading = "";
      }
      try {
        box.subtitle = worksheet["B4"].v;
      } catch (err) {
        box.subtitle = "";
      }
      try {
        box.column_1 = worksheet["D5"].v;
      } catch (err) {
        box.column_1 = "";
      }
      try {
        box.column_2 = worksheet["E5"].v;
      } catch (err) {
        box.column_2 = "";
      }
      try {
        box.column_3 = worksheet["F5"].v;
      } catch (err) {
        box.column_3 = "";
      }
      try {
        box.column_4 = worksheet["G5"].v;
      } catch (err) {
        box.column_4 = "";
      }

      for (let i = 0; i < 10; i++) {
        let row = { ...box.rows[i] };
        try {
          row.badge = worksheet["I" + (6 + i)].v;
        } catch (err) {
          row.badge = "";
        }
        try {
          row.text = worksheet["B" + (6 + i)].v;
        } catch (err) {
          row.text = "";
        }

        try {
          row.column_1 = worksheet["D" + (6 + i)].v;
        } catch (err) {
          row.column_1 = "";
        }
        try {
          row.column_2 = worksheet["E" + (6 + i)].v;
        } catch (err) {
          row.column_2 = "";
        }
        try {
          row.column_3 = worksheet["F" + (6 + i)].v;
        } catch (err) {
          row.column_3 = "";
        }
        try {
          row.column_4 = worksheet["G" + (6 + i)].v;
        } catch (err) {
          row.column_4 = "";
        }

        box.rows[i] = row;
      }

      onUpdate(box);
    };
    reader.readAsArrayBuffer(f);
  }

  return (
    <Modal
      style={{ width: "70%", height: "90vh" }}
      show={cornerBox}
      onCancel={() => {
        onCancel();
      }}
    >
      {cornerBox && (
        <Panel>
          <PanelTitle title={"Corner Box Editor"}>
            <input type="file" onChange={handleFile} />
          </PanelTitle>
          <PanelContent>
            <CornerBoxEdit
              game={game}
              home_team={home_team}
              away_team={away_team}
              box={{ ...cornerBox }}
              onChange={(box) => {
                onUpdate(box);
              }}
            ></CornerBoxEdit>
          </PanelContent>
          <SavePanel
            showDelete={true}
            style={{ marginLeft: "auto" }}
            onSave={() => {
              let page = originalCornerBox?.number;

              updateStatBox(cornerBox, page != cornerBox.number).then(() => {
                onSave();
              });
            }}
            onCancel={() => {
              onCancel();
            }}
            onDelete={onDelete}
          />
        </Panel>
      )}
    </Modal>
  );
}

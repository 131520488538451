import React from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";

const Main = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  padding: 0.5em;
  width: 100%;
`;

const PageButton = styled(Button)`
  border: 0;
  min-width: 35px;
  height: 40px;
  text-align: center;
  padding: 11px 0;
  background-color: ${(props) => (props.selected ? "#14ec8e" : "#e9ecef")};
  color: ${(props) => (props.selected ? "#fff" : "")};
  border-radius: 3px;
  transition: background-color 0.25s linear;
  margin-right: 3px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => (props.selected ? "#14ec8e" : "#dee2e6")};
  }
`;

const Dummy = styled.div`
  min-width: 35px;
`;
export default function Pagination({ count, limit, onChange, page }) {
  let length = parseInt(Math.max(count / limit, 1));

  return (
    <Main>
      {page > 1 && (
        <PageButton
          onClick={() => {
            onChange(page - 1);
          }}
        >
          {"<"}
        </PageButton>
      )}
      {page === 1 && <Dummy></Dummy>}

      {new Array(length).fill(1).map((item, index) => {
        return (
          <PageButton
            selected={index + 1 === page}
            total={length}
            onClick={() => {
              onChange(index + 1);
            }}
          >
            {index + 1}
          </PageButton>
        );
      })}
      {page < length && (
        <PageButton
          onClick={() => {
            onChange(page + 1);
          }}
        >
          {">"}
        </PageButton>
      )}
      {page === length && <Dummy style={{ opacity: 0 }}></Dummy>}
    </Main>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import Input from "../UI/Form/Input";
import { Button } from "../../Theme/Hyper";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
const Row = styled.div`
  display: flex;
  padding: 0.5em;
  width: 100%;
  font-size: 0.8em;
  font-weight: 100;
  align-items: center;
  :nth-of-type(even) {
    background-color: #0000000d;
  }
`;
const Label = styled.div`
  display: flex;
  min-width: 150px;
`;

export default function Variables({ team }) {
  const [variable, setVariable] = useState();

  return (
    <Main>
      {team.variables &&
        team.variables.map((v) => {
          return (
            <Row>
              <Label>Var 1</Label>
            </Row>
          );
        })}
      <Row>
        <Input value={""} />
        <Button>Add</Button>
      </Row>
    </Main>
  );
}

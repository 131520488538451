import React, { useState } from "react";
import styled from "styled-components";
import ProgressButtons from "./ProgressButtons";
import Stage from "./Stage";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;
const Stages = styled.div`
  display: flex;
`;
const Content = styled.div`
  display: flex;
  width: 100%;
`;
export default function Progress({ children, stages, onSave, edit }) {
  const [stage, setStage] = useState(0);

  return (
    <Main>
      <Stages>
        {stages.map((s, index) => {
          return (
            <Stage
              title={s}
              stage={index + 1}
              current={stage === index}
              onClick={(stage) => {
                setStage(stage - 1);
              }}
            />
          );
        })}
      </Stages>
      <Content>
        {React.cloneElement(children[stage], {
          stage: stage + 1,
        })}
      </Content>

      <ProgressButtons
        edit={edit}
        stage={stage}
        total={stages.length}
        onNext={() => {
          setStage(stage + 1);
        }}
        onPrevious={() => {
          setStage(stage - 1);
        }}
        onSave={onSave}
      />
    </Main>
  );
}

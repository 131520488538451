import React, { useCallback, useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import CompetitionList from "../Competition/CompetitionList";
import SeasonList from "../Season/SeasonList";
import { UIContext } from "../../contexts/UIContext";
import { Button, ScrollBar } from "../../Theme/Hyper";
import Input from "../UI/Form/Input";

const Main = styled.div`
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 0.5rem;
  color: #606770;
  background-color: #fff;
  flex-direction: column;
  overflow-y: scroll;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;
const TR = styled.tr`
  > td {
    background-color: ${(props) => (props.active ? "#14ec8e" : "")};
  }
  color: ${(props) => (props.active ? "white" : "")};
  cursor: pointer;
`;
const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function FullTeamList({
  seasons,
  selectedTeams,
  onTeamSelected,
}) {
  const { getTeams } = useContext(DataContext);
  const [teams, setTeams] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getTeams().then((data) => {
      setTeams([...(data || [])]);
    });
  }, []);

  return (
    <Main>
      <Input
        value={search}
        title="Search"
        onChange={(e) => {
          debugger;
          setSearch(e.currentTarget.value);
        }}
      />
      <Table>
        <thead>
          <tr>
            <TH>ID</TH>
            <TH>OPTA ID</TH>
            <TH>Name</TH>
          </tr>
        </thead>
        <tbody>
          {teams
            ?.filter((t) => {
              debugger;
              if (search !== "") {
                return t?.name?.toLowerCase()?.startsWith(search);
              }
              return true;
            })
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            ?.map((team) => {
              return (
                <TR
                  active={selectedTeams?.indexOf(team._id) > -1}
                  onClick={() => {
                    onTeamSelected(team);
                  }}
                >
                  <td>{team._id}</td>
                  <td>{team.opta_ID}</td>
                  <td>{team.name}</td>
                  <td>
                    {seasons
                      ?.filter((s) => {
                        return (
                          s?.teams?.findIndex((t) => t?._id === team?._id) > -1
                        );
                      })
                      ?.map((s) => {
                        return s.competition?.name + "-" + s.name;
                      })
                      ?.join(",")}
                  </td>
                </TR>
              );
            })}
        </tbody>
      </Table>
    </Main>
  );
}

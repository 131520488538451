import React from "react";
import styled from "styled-components";
import Tooltip from "../UI/Tooltip";
import Stat from "./Stat";
const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: #293b52;
  color: white;
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
`;
const Category = styled.div`
  display: flex;
  min-width: 40%;
  justify-content: center;
  padding: 0.5em;
`;
export default function Stats({ game }) {
  return (
    game && (
      <>
        <Header>
          <Value>
            <Tooltip text="First Half" colour={"white"}>
              FH
            </Tooltip>
          </Value>
          <Value>
            <Tooltip text="Second Half" colour={"white"}>
              SH
            </Tooltip>
          </Value>
          <Value>
            <Tooltip text="Total" colour={"white"}>
              T
            </Tooltip>
          </Value>
          <Category>Statistic</Category>
          <Value>
            <Tooltip text="First Half" colour={"white"}>
              FH
            </Tooltip>
          </Value>
          <Value>
            <Tooltip text="Second Half" colour={"white"}>
              SH
            </Tooltip>
          </Value>
          <Value>
            <Tooltip text="Total" colour={"white"}>
              T
            </Tooltip>
          </Value>
        </Header>
        <BuildStat
          game={game}
          title={"Goals"}
          category={"goals"}
          categoryF9={"goals"}
        />
        <BuildStat
          game={game}
          title={"Attempts"}
          category={"totalScoringAtt"}
          categoryF9={"total_scoring_att"}
        />
        <BuildStat
          game={game}
          title={"On Target"}
          category={"ontargetScoringAtt"}
          categoryF9={"ontarget_scoring_att"}
        />
        <BuildStat
          game={game}
          title={"Touches in opp. box"}
          category={"touchesInOppBox"}
          categoryF9={"touches_in_opp_box"}
        />
        <BuildStat
          game={game}
          title={"Corners"}
          category={"wonCorners"}
          categoryF9={"won_corners"}
        />
        <BuildStat
          game={game}
          title={"Fouls conceded"}
          category={"fkFoulLost"}
          categoryF9={"fk_foul_lost"}
        />
        <BuildStat
          game={game}
          title={"Offsides"}
          category={"totalOffside"}
          categoryF9={"total_offside"}
        />
        <BuildStat
          game={game}
          title={"Passes"}
          category={"totalPass"}
          categoryF9={"total_pass"}
        />
        <BuildStat
          game={game}
          title={"Successful passes"}
          category={"accuratePass"}
          categoryF9={"accurate_pass"}
        />
        <BuildStat
          game={game}
          title={"Passing accuracy"}
          category={"passAccuracy"}
          categoryF9={"passAccuracy"}
        />
        <BuildStat
          game={game}
          title={"Possession"}
          category={"possessionPercentage"}
          categoryF9={"possession_percentage"}
        />
        <BuildStat
          game={game}
          title={"Yellow cards"}
          category={"totalYellowCard"}
          categoryF9={"total_yel_card"}
        />
        <BuildStat
          game={game}
          title={"Red cards"}
          category={"totalRedCard"}
          categoryF9={"total_red_card"}
        />
        <BuildStat
          game={game}
          title={"Crosses"}
          category={"totalCross"}
          categoryF9={"total_cross"}
        />
        <BuildStat
          game={game}
          title={"Interceptions"}
          category={"interception"}
          categoryF9={"interception"}
        />
        <BuildStat
          game={game}
          title={"Tackles"}
          category={"totalTackle"}
          categoryF9={"total_tackle"}
        />
        <BuildStat
          game={game}
          title={"Tackles won"}
          category={"wonTackle"}
          categoryF9={"won_tackle"}
        />
        <BuildStat
          game={game}
          title={"Direct free-kick attempts"}
          category={"attFreeKickTotal"}
          categoryF9={"att_free_kick_total"}
        />
        <BuildStat
          game={game}
          title={"Direct free-kick goals"}
          category={"attFreeKickGoal"}
          categoryF9={"att_free_kick_goal"}
        />
        <BuildStat
          game={game}
          title={"Headed attempts"}
          category={"attHdTotal"}
          categoryF9={"att_hd_total"}
        />
        <BuildStat
          game={game}
          title={"Headed goals"}
          category={"attHdGoal"}
          categoryF9={"att_hd_goal"}
        />
        <BuildStat
          game={game}
          title={"Hit woodwork"}
          category={"hitWoodwork"}
          categoryF9={"hit_woodwork"}
        />
      </>
    )
  );
}

function BuildStat({ game, title, category }) {
  let homeStat = game?.opta_team_stats?.home?.find((s) => s.type === category);
  let awayStat = game?.opta_team_stats?.away?.find((s) => s.type === category);

  if (category !== "touchesInOppBox") {
    homeStat = { ...homeStat, value: game?.stats?.home_team[category] };
    awayStat = { ...awayStat, value: game?.stats?.away_team[category] };
  }
  try {
    if (category === "passAccuracy") {
      let accuratePassHome = game?.opta_team_stats?.home?.find(
        (s) => s.type === "accuratePass"
      );
      let totalPassHome = game?.opta_team_stats?.home?.find(
        (s) => s.type === "totalPass"
      );
      homeStat = {
        fh: Math.round(
          (parseInt(accuratePassHome.fh || 0) /
            parseInt(totalPassHome.fh || 0)) *
            100
        ),
        sh:
          Math.round(
            (parseInt(accuratePassHome.sh) / parseInt(totalPassHome.sh)) * 100
          ) || 0,
        value: Math.round(game?.stats?.home_team[category] || 0),
      };

      let accuratePassAway = game?.opta_team_stats?.away.find(
        (s) => s.type === "accuratePass"
      );
      let totalPassAway = game?.opta_team_stats?.away.find(
        (s) => s.type === "totalPass"
      );

      awayStat = {
        fh: Math.round(
          (parseInt(accuratePassAway.fh || 0) /
            parseInt(totalPassAway.fh || 0)) *
            100
        ),
        sh:
          Math.round(
            (parseInt(accuratePassAway.sh) / parseInt(totalPassAway.sh)) * 100
          ) || 0,
        value: Math.round(game?.stats?.away_team[category] || 0),
      };
    }
  } catch (err) {
    console.log(err);
  }

  return (
    <Stat
      category={title}
      homeColour={
        game?.home_team?.variables &&
        game.home_team.variables[game.home_team_kit + "_kit_hex"]
      }
      awayColour={
        game?.away_team?.variables &&
        game.away_team.variables[game.away_team_kit + "_kit_hex"]
      }
      game={game}
      stat={{
        homeFH: (homeStat && parseFloat(homeStat.fh)) || 0,
        homeSH: (homeStat && parseFloat(homeStat.sh)) || 0,
        home: (homeStat && parseFloat(homeStat.value)) || 0,
        awayFH: (awayStat && parseFloat(awayStat.fh)) || 0,
        awaySH: (awayStat && parseFloat(awayStat.sh)) || 0,
        away: (awayStat && parseFloat(awayStat.value)) || 0,
      }}
    />
  );
}

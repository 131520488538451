import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import PlayerList from "../Player/PlayerList";
import PanelTitle from "../UI/Panel/PanelTitle";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import Lineup from "../Lineup/Lineup";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import Pagination from "../UI/Pagination/Pagination";
import PlayerEditor from "../Modals/PlayerEditor";
import { Button } from "../../Theme/Hyper";
import { UIContext } from "../../contexts/UIContext";
import TransferList from "../Transfer/TransferList";

const Main = styled.div`
  margin-top: 52px;
  padding-top: 52px;
  margin-bottom: 52px;
`;

export default function () {
  const { selectedSeason } = useContext(UIContext);
  return (
    <Main>
      <Button
        onClick={() => {
          fetch(
            "http://localhost:8082/opta/transfers/" + selectedSeason.opta_ID
          );
        }}
      >
        Update OPTA
      </Button>
      <TransferList />
    </Main>
  );
}
